import { IAftnMessage, IMetar, ITaf } from '@naviair-utm/node-shared-interfaces';
import moment from 'moment';
import React from 'react';
import { TMetarTaf } from '../../interfaces';
import { convertTime, tag } from '../InfoPage/TableCol/helpers';
import './styles.scss';

export interface IMetarTafIndex extends TMetarTaf {
	index?: number; // Used to create a key
}

export const MetarTafHeader: React.FC<IMetarTafIndex> = (props) => (
	<div className={'aftnRow aftnHeader'}>
		<div className={'aftnDataCol bold'}>{`${props.icao}`}</div>
	</div>
);

export const MetarTafContent: React.FC<IMetarTafIndex> = (props) => (
	<>
		<SpeciContent {...props} />
		<MetarContent {...props} />
		<TafContent {...props} />
	</>
);

export const MetarTafContentMobile: React.FC<IMetarTafIndex> = (props) => (
	<>
		<SpeciContentMobile {...props} />
		<MetarContentMobile {...props} />
		<TafContentMobile {...props} />
	</>
);

export const SpeciContent: React.FC<IMetarTafIndex> = (props) => (
	<React.Fragment key={`speci_content_content_${props.index}`}>
		{props.metar.map((speci, index) => {
			// Filter METARs so only SPECIs are shown
			if (speci.type.toLocaleUpperCase().includes('SPECI')) {
				const speciValidity = getValidityTag(speci);
				return (
					<div key={`speci${index}`} className={'aftnRow aftnContent'}>
						<div className={'aftnCol metarTafTitle'}>{'SPECI'}</div>
						<div className={'aftnCol metarTafRaw'}>{speci.rawInput}</div>
						<div className={'aftnCol tagContainer metarTafTagContainer'}>{tag(speciValidity.text, !speciValidity.valid)}</div>
					</div>
				);
			}
		})}
	</React.Fragment>
);

export const MetarContent: React.FC<IMetarTafIndex> = (props) => (
	<React.Fragment key={`metar_content_content_${props.index}`}>
		{props.metar.map((metar, index) => {
			// Filter SPECIs so only METARs are shown
			if (metar.type.toLocaleUpperCase().includes('METAR')) {
				const metarValidity = getValidityTag(metar, props.metar);
				return (
					<div key={`metar${index}`} className={'aftnRow aftnContent'}>
						<div className={'aftnCol metarTafTitle'}>{'METAR'}</div>
						<div className={'aftnCol metarTafRaw'}>{metar.rawInput}</div>
						<div className={'aftnCol tagContainer metarTafTagContainer'}>{tag(metarValidity.text, !metarValidity.valid)}</div>
					</div>
				);
			}
		})}
	</React.Fragment>
);

export const TafContent: React.FC<IMetarTafIndex> = (props) => (
	<React.Fragment key={`taf_content_container_${props.index}`}>
		{props.taf.map((taf, index) => {
			const tafValidity = getValidityTag(taf);
			return (
				<React.Fragment key={`taf_content_${props.index}_${index}`}>
					<div key={`taf${index}`} className={'aftnRow aftnContent'}>
						<div className={'aftnCol metarTafTitle'}>{'TAF'}</div>
						<div className={'aftnCol metarTafRaw'}>{taf.rawInput}</div>
						<div className={'aftnCol tagContainer metarTafTagContainer'}>{tag(tafValidity.text, !tafValidity.valid)}</div>
					</div>
				</React.Fragment>
			);
		})}
	</React.Fragment>
);

export const SpeciContentMobile: React.FC<IMetarTafIndex> = (props) => (
	<React.Fragment key={`speci_content_mobile_container_${props.index}`}>
		{props.metar.map((speci, index) => {
			// Filter METARs so only SPECIs are shown
			if (speci.type.toLocaleUpperCase().includes('SPECI')) {
				const speciValidity = getValidityTag(speci);
				return (
					<React.Fragment key={`speci_content_mobile_${props.index}`}>
						<div className={'aftnRow aftnContent'} key={`speci_mobile${index}`}>
							<div className={'aftnCol metarTafTitle mobile'}>
								{'SPECI'}
								<div className={'aftnCol tagContainer mobile'}>{tag(speciValidity.text, !speciValidity.valid)}</div>
							</div>
						</div>
						<div className={'aftnRow noSpace aftnContent aftnRowMsg'} key={`speci_mobile_msg${index}`}>
							<div className={'aftnCol aftnMessageMobile mobile'}>{speci.rawInput}</div>
						</div>
					</React.Fragment>
				);
			}
		})}
	</React.Fragment>
);

export const MetarContentMobile: React.FC<IMetarTafIndex> = (props) => (
	<React.Fragment key={`metar_content_mobile_container_${props.index}`}>
		{props.metar.map((metar, index) => {
			// Filter SPECIs so only METARs are shown
			if (metar.type.toLocaleUpperCase().includes('METAR')) {
				const metarValidity = getValidityTag(metar, props.metar);
				return (
					<React.Fragment key={`metar_content_mobile_${props.index}`}>
						<div className={'aftnRow aftnContent'} key={`metar_mobile${index}`}>
							<div className={'aftnCol metarTafTitle mobile'}>
								{'METAR'}
								<div className={'aftnCol tagContainer mobile'}>{tag(metarValidity.text, !metarValidity.valid)}</div>
							</div>
						</div>
						<div className={'aftnRow noSpace aftnContent aftnRowMsg'} key={`metar_mobile_msg${index}`}>
							<div className={'aftnCol aftnMessageMobile mobile'}>{metar.rawInput}</div>
						</div>
					</React.Fragment>
				);
			}
		})}
	</React.Fragment>
);

export const TafContentMobile: React.FC<IMetarTafIndex> = (props) => (
	<React.Fragment key={`taf_content_mobile_container_${props.index}`}>
		{props.taf.map((taf, index) => {
			const tafValidity = getValidityTag(taf);
			return (
				<React.Fragment key={`taf_content_mobile_${props.index}_${index}`}>
					<div className={'aftnRow aftnContent'} key={`taf_mobile${index}`}>
						<div className={'aftnCol metarTafTitle mobile'}>
							{'TAF'}
							<div className={'aftnCol tagContainer mobile'}>{tag(tafValidity.text, !tafValidity.valid)}</div>
						</div>
					</div>
					<div className={'aftnRow aftnContent aftnRowMsg'} key={`taf_mobile_row_${index}`}>
						<div key={`taf_mobile_msg${index}`} className={'aftnCol aftnMessageMobile mobile'}>
							{taf.rawInput}
						</div>
					</div>
				</React.Fragment>
			);
		})}
	</React.Fragment>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const getValidityTag = (metar_taf: IAftnMessage<IMetar | ITaf>, messages?: IAftnMessage<IMetar | ITaf>[]): { text: string; valid: boolean } => {
	const rawMessage = metar_taf.rawInput;
	if (rawMessage.includes('CNL')) {
		return { text: 'CANCELLED', valid: false };
	} else if (rawMessage.includes('NIL')) {
		return { text: 'NO ITEM LISTED', valid: false };
	} else if (metar_taf.type === 'METAR') {
		const metar = metar_taf as IAftnMessage<IMetar>;
		// Find the time difference in minutes between now and the time of the METAR
		const timeDiff = moment(new Date()).diff(moment(metar.validity?.start), 'minutes');
		// Loop through messages and see if any SPECI is present, if so invalidate the METAR
		const speci = messages?.find((msg) => msg.type === 'SPECI' && msg.icao === metar.icao);
		if (speci) {
			// A SPECI is present, the METAR is therefore invalid
			return { text: 'EXPIRED', valid: false };
		} else if (timeDiff > 70) {
			// The METAR is older than 70 minutes, it is therefore invalid (70 min. is 60 min. with a 10 min buffer for latency)
			return { text: 'EXPIRED', valid: false };
		} else if (timeDiff < 70) {
			// The METAR is younger than 70 minutes, it is therefore valid
			return { text: convertTime(metar.validity?.start), valid: true };
		} else {
			return { text: 'UNKNOWN', valid: false };
		}
	} else if (metar_taf.type === 'TAF') {
		const taf = metar_taf as IAftnMessage<ITaf>;
		// Find the time difference in minutes between now and the time of the TAF
		const expired = moment(taf.validity?.end) < moment(new Date());
		if (expired) {
			// The TAF end validity is older than now
			return { text: 'EXPIRED', valid: false };
		} else if (taf.parsedMessage.validityPeriod) {
			// There is a validity period in the TAF, then return this
			return { text: `${taf.parsedMessage.validityPeriod} hours`, valid: true };
		} else {
			return { text: 'UNKNOWN', valid: false };
		}
	} else if (metar_taf.type === 'SPECI') {
		const speci = metar_taf as IAftnMessage<IMetar>;
		// This does not really check for validity as it requires checking all METARs for the same ICAO code
		return { text: convertTime(speci.validity?.start), valid: true };
	} else {
		return { text: 'UNKNOWN', valid: false };
	}
};

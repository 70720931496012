import {
    EAftnTypes,
    TSettingsMapLocationIndicator,
    TSettingsMapZoneProperties,
    TSettingsMapZoneColors
} from '@naviair-gl/node-shared-interfaces';
import { IAftnMessage, INotam, INotamLimits, TAftnResponse, TNotamTypeDesc } from '@naviair-utm/node-shared-interfaces';
import {ENotamScheduleStates, notamScheduleStatus} from '@naviair-utm/aftn-message-parser';
import {Feature, Point, Polygon} from 'geojson';
import mapboxgl from 'mapbox-gl';
import {NavigateFunction} from 'react-router-dom';
import {TNotamModalState} from '..';
import {styles} from '../../../../Styles/styles';
import {TMarkerAddedEvent} from '../Map';
import {addMarker, removeMarker} from '../mapControlHelper';
import pattern from './pattern.png';
import './popup.scss';

/* eslint-disable @typescript-eslint/naming-convention */
export const MAPBOX_LAYERS = {
    MAPBOX_SOURCE_ID: 'aftn-zones',
    MAPBOX_LAYER_POINT_ID: 'aftn-points',
    MAPBOX_LAYER_POINT_LABEL_ID: 'aftn-label',
    MAPBOX_LAYER_FIR_ID: 'aftn-fir',
    MAPBOX_LAYER_FIR_LABEL_ID: 'aftn-fir-label',
    MAPBOX_LAYER_AREA_ID: 'aftn-areas',
    MAPBOX_SOURCE_NOTAM_ID: 'aftn-notams',
    MAPBOX_LAYER_NOTAM_POLYGON_FILL_ID: 'aftn-notams-polygons-fill',
    MAPBOX_LAYER_NOTAM_POLYGON_PATTERN_ID: 'aftn-notams-polygons-pattern',
    MAPBOX_LAYER_NOTAM_LINE_ID: 'aftn-notams-lines'
};

/** List of filters for use with the Mapbox layers */
export const LAYER_FILTERS = {
    NOTAM_POLYGON_PATTERN_ID: ['all', ['==', '$type', 'Polygon'], ['==', 'visible', true], ['==', 'pattern', true]],
    NOTAM_POLYGON_PATTERN_ID_ALTITUDE_FILTER: ['all', ['==', '$type', 'Polygon'], ['==', 'visible', true], ['==', 'pattern', true], ['==', 'altitudeFilterShow', true]],

    NOTAM_FILL_ID: ['all', ['==', '$type', 'Polygon'], ['==', 'visible', true]],
    NOTAM_FILL_ID_ALTITUDE_FILTER: ['all', ['==', '$type', 'Polygon'], ['==', 'visible', true], ['==', 'altitudeFilterShow', true]],

    NOTAM_LINE_ID: ['all', ['==', '$type', 'Polygon'], ['==', 'visible', true]],
    NOTAM_LINE_ID_ALTITUDE_FILTER: ['all', ['==', '$type', 'Polygon'], ['==', 'visible', true], ['==', 'altitudeFilterShow', true]],
};
/* eslint-enable @typescript-eslint/naming-convention */

/**
 *
 * @param icaoSearch
 * @param aftnMessages
 * @param availableAftnTypes
 * @returns boolean
 * @description Check if there is any messages for the current locationIndicator
 */
const checkMessages = (icaoSearch: string, aftnMessages?: TAftnResponse, availableAftnTypes?: string[]): boolean => {
    let counter = 0;
    aftnMessages?.forEach((aftnMessageData) => {
        // Only count if aftn type is included
        if (availableAftnTypes?.includes(aftnMessageData.type)) {
            const data: IAftnMessage[] = aftnMessageData.data;

            // If it is a notam, check the raw field A for the icao code. If not, then just use the icao in the main object
            let dataFiltered;
            if (aftnMessageData.type === EAftnTypes.NOTAM) {
                const notam: IAftnMessage<INotam>[] = aftnMessageData.data as IAftnMessage<INotam>[];
                dataFiltered = notam.filter((message) => message.parsedMessage.rawFields?.a === icaoSearch);
            } else {
                dataFiltered = data.filter((message) => message.icao === icaoSearch);
            }

            counter += dataFiltered.length;
        }
    });
    return counter > 0;
};


/**
 * Add a property to the NOTAM for whether the message should be displayed if the FL195 limit is enforced
 * @param notam The message to check
 */
const checkAltitudeLimit = (notam: IAftnMessage<INotam>): boolean => {
    let showOnFilter = true;
    if (notam.parsedMessage.limits?.lower?.altitude) {
        switch (notam.parsedMessage.limits.lower.units) {
            case 'FT':
                showOnFilter = !(notam.parsedMessage.limits.lower.altitude >= 19500);
                break;
            case 'FL':
                showOnFilter = !(notam.parsedMessage.limits.lower.altitude >= 195);
                break;
        }
    } // Default return true (show) if no altitude is defined

    return showOnFilter;
};

/**
 *
 * @param map
 * @param zones
 * @param locationIndicatorSettings
 * @param aftnMessages
 * @param availableAftnTypes The array of AFTN types enabled in the configuration
 * @param mapColorSettings
 * @param altitudeFilterFeatureEnabled
 * @description Handle adding of zones to map-component
 */
export const addZones = (
    map?: mapboxgl.Map,
    zones?: GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.Point | GeoJSON.MultiPolygon, TSettingsMapZoneProperties>,
    locationIndicatorSettings?: TSettingsMapLocationIndicator,
    aftnMessages?: TAftnResponse,
    availableAftnTypes?: EAftnTypes[],
    mapColorSettings?: TSettingsMapZoneColors,
    altitudeFilterFeatureEnabled?: boolean
): void => {
    if (map && zones && aftnMessages) {

        // Check if there is any aftnMessages for zones and set attribute active on features
        const newFeatures = zones.features.map((feature) => ({
            ...feature,
            properties: {
                ...feature.properties,
                active: checkMessages(feature.properties.name, aftnMessages, availableAftnTypes)
            },
        })).filter(feature => {
            const now: number = Date.now();
            let valid = true;

            // Determine whether the zone is visible depending on the UNIX timestamps in the properties
            if (feature.properties.visibleFrom && now - feature.properties.visibleFrom < 0) valid = false;
            if (feature.properties.visibleUntil && now - feature.properties.visibleUntil > 0) valid = false;

            return valid;
        });

        addNotamsToMap(map, aftnMessages[3].data, mapColorSettings, altitudeFilterFeatureEnabled);
        addLocationsToMap(map, {...zones, features: newFeatures}, locationIndicatorSettings);
    }
};

const checkValidityNotam = (notam: IAftnMessage<INotam>) => {
    const notamSchedule = notamScheduleStatus(notam);
    const now = new Date().getTime() / 1000;
    const today = {
        start: new Date().setHours(0, 0, 0, 0) / 1000,
        end: new Date().setHours(24, 0, 0, 0) / 1000,
    };
    const validityUnix = {
        start: notam.validity?.start ? new Date(notam.validity.start).getTime() / 1000 : 0,
        end: notam.validity?.end ? new Date(notam.validity.end).getTime() / 1000 : 0,
    };
    if (validityUnix.start < today.end) {
        //Ensure that NOTAM activity starts today
        if (now > validityUnix.start) {
            if (notamSchedule === ENotamScheduleStates.NO_SCHEDULE || notamSchedule === ENotamScheduleStates.ACTIVE) {
                //Activity active now
                return 'now';
            } else if (notamSchedule === ENotamScheduleStates.INACTIVE) {
                // Notam is active, but before time frame
                return 'later';
            } else if (notamSchedule === ENotamScheduleStates.DISABLED) {
                // Notam is active, but past time frame
                return 'future';
            }
        } else {
            //Activity active later today
            return 'later';
        }
    } else {
        //NOTAM there is active in the furture
        return 'future';
    }
};

// Return color for the current notam
const getNotamColor = (qCode?: string, validity?: 'now' | 'later' | 'future', mapColorSettings?: TSettingsMapZoneColors, qScope?: TNotamTypeDesc[]) => {
    if (qCode) {
        //Restricted/Danger
        if (['RDCA', 'RRCA', 'RTCA', 'RPCA'].includes(qCode) && validity === 'now') {
            return `#${mapColorSettings?.restrictedClosed ?? 'FF0000'}`;
            //Restricted/Danger later
        } else if (['RDCA', 'RRCA', 'RTCA', 'RPCA'].includes(qCode) && validity === 'later') {
            return `#${mapColorSettings?.restrictedClosedLater ?? 'FFFFA7'}`;
            //Warning zones
        } else if (['RACA', 'RALW', 'RMCA', 'ROLP', 'ROLT'].includes(qCode) && validity === 'now') {
            return `#${mapColorSettings?.warning ?? 'FFA500'}`;
            //Obstacels
        } else if (['OBCE'].includes(qCode) && validity === 'now' && qScope && qScope.filter((scope) => ['E'].includes(scope.code)).length > 0) {
            return `#${mapColorSettings?.obstacle ?? '29B6F6'}`;
            //Obstacels light
        } else if (['OLAS'].includes(qCode) && validity === 'now' && qScope && qScope.filter((scope) => ['E'].includes(scope.code)).length > 0) {
            return `#${mapColorSettings?.obstacleLight ?? '26C6DA'}`;
        }
    }
};

//Extended interface for props on feature
interface IFeatureProps extends IAftnMessage<INotam> {
    color?: string;
    visible: boolean;
    pattern?: boolean;
    altitudeLimits?: INotamLimits;
    altitudeFilterShow: boolean;
}

const addNotamsToMap = (map: mapboxgl.Map, notams: IAftnMessage<INotam>[], mapColorSettings?: TSettingsMapZoneColors, altitudeFilterFeatureEnabled?: boolean) => {
    // Take the NOTAMs passed as prop and sort them by their validity so that active NOTAMs are on top
    const notamsSorted = [...notams].sort((a, b) => {
        const validityA = checkValidityNotam(a);
        const validityB = checkValidityNotam(b);

        // Sort the NOTAMs so that the ones that are active now are on top
        if (validityA === validityB) {
            return 0;
        } else if (validityA === 'now') {
            return 1;
        } else if (validityB === 'now') {
            return -1;
        } else {
            return 0;
        }
    });

    const parseNotamsToGeoJSON = (notams?: IAftnMessage<INotam>[]) => {
        return notams?.map((notam) => {
            const qCode = notam.parsedMessage.qualification?.code?.code;
            const qScope = notam.parsedMessage.qualification?.scope;
            const validity = checkValidityNotam(notam);
            const color = getNotamColor(qCode, validity, mapColorSettings, qScope);
            const feature: Feature<Polygon | Point, IFeatureProps> = {
                type: 'Feature',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                geometry: notam.parsedMessage.geojson!,
                properties: {
                    ...notam,
                    color: color,
                    pattern: ['RDCA', 'RRCA', 'RTCA', 'RPCA'].includes(qCode ?? '') && validity === 'later',
                    //Check if color is set and validity to show
                    visible: validity !== 'future' && color !== undefined,
                    altitudeLimits: notam.parsedMessage.limits,
                    altitudeFilterShow: altitudeFilterFeatureEnabled ? checkAltitudeLimit(notam) : true, // If the filter is on, then run the check, if off, then just return true to all features
                },
            };
            return feature;
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !map.getSource(MAPBOX_LAYERS.MAPBOX_SOURCE_NOTAM_ID) &&
    map.addSource(MAPBOX_LAYERS.MAPBOX_SOURCE_NOTAM_ID, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: parseNotamsToGeoJSON(notamsSorted) ?? [],
        },
        generateId: true, //Needed to generate id for hover
    });

    //Add the polygon to map
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_FILL_ID) &&
    map.loadImage(pattern, (err, image) => {
        // Throw an error if something goes wrong.
        if (err) throw err;

        /* Add pattern background to map style. */
        image && !map.hasImage('pattern') && map.addImage('pattern', image);

        /* Add background patterns if NOTAM is marked as "LATER" */
        map.addLayer(
            {
                id: MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_PATTERN_ID,
                type: 'fill',
                source: MAPBOX_LAYERS.MAPBOX_SOURCE_NOTAM_ID,
                paint: {
                    'fill-opacity': 0.5,
                    'fill-pattern': 'pattern',
                },
                filter: LAYER_FILTERS.NOTAM_POLYGON_PATTERN_ID_ALTITUDE_FILTER, // By default, the altitude filter is enabled
            },
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID) && MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID // Place pattern layer below the location point layer
        );

        /* Add notam fill layer */
        map.addLayer(
            {
                id: MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_FILL_ID,
                type: 'fill',
                source: MAPBOX_LAYERS.MAPBOX_SOURCE_NOTAM_ID,
                paint: {
                    'fill-color': ['get', 'color'],
                    'fill-opacity': 0.3,
                },
                filter: LAYER_FILTERS.NOTAM_FILL_ID_ALTITUDE_FILTER, // By default, the altitude filter is enabled
            },
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID) && MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID // Place polygon layer below the location point layer
        );
    });

    //Add line around polygon to map
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_LINE_ID) &&
    map.addLayer(
        {
            id: MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_LINE_ID,
            type: 'line',
            source: MAPBOX_LAYERS.MAPBOX_SOURCE_NOTAM_ID,
            paint: {
                'line-color': ['get', 'color'],
                'line-width': 1,
            },
            filter: LAYER_FILTERS.NOTAM_LINE_ID_ALTITUDE_FILTER, // By default, the altitude filter is enabled
        },
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID) && MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID
    );
};

const addLocationsToMap = (
    map: mapboxgl.Map,
    zones?: GeoJSON.FeatureCollection<GeoJSON.Polygon | GeoJSON.Point | GeoJSON.MultiPolygon, TSettingsMapZoneProperties>,
    locationIndicatorSettings?: TSettingsMapLocationIndicator,
    mapIcao?: string
) => {
    const bubbleSettings = {
        // The default bubble size
        bubbleSize: locationIndicatorSettings?.size ?? 10,
        // Multiplier if it is a big bubble (e.g. FIR)
        bubbleHoverMultiplier: locationIndicatorSettings?.hoverMultiplier ?? 1.4,
        // Size multiplier on hover. Will also apply to big bubbles
        bigBubbleMultiplier: locationIndicatorSettings?.bigBubbleMultiplier ?? 1.5,

        /* If any active notam in this area, show a blue dot instead */
        // Whether to check for active NOTAMs or not
        activeIndicator: locationIndicatorSettings?.activeIndicator ?? false,
        // The color of an active ICAO
        activeColor: locationIndicatorSettings?.activeColor ?? '#0996AE',
        // The code to search for when retrieving all AFTN messages (e.g. EKDK)
        activeIcaoSearch: mapIcao,
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !map.getSource(MAPBOX_LAYERS.MAPBOX_SOURCE_ID) &&
    map.addSource(MAPBOX_LAYERS.MAPBOX_SOURCE_ID, {
        type: 'geojson',
        data: zones,
        generateId: true, //Needed to generate id for hover
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_POINT_LABEL_ID) &&
    !locationIndicatorSettings?.hideLabels &&
    map.addLayer({
        id: MAPBOX_LAYERS.MAPBOX_LAYER_POINT_LABEL_ID,
        type: 'symbol',
        source: MAPBOX_LAYERS.MAPBOX_SOURCE_ID,
        layout: {
            'text-field': ['get', 'name'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.8],
            'text-anchor': 'top',
            'text-allow-overlap': true,
        },
        paint: {
            'text-color': styles.COLOR_TEXT_MAP,
        },
        filter: ['==', ['get', 'type'], 'point'],
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID) &&
    map.addLayer(
        {
            id: MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID,
            type: 'circle',
            source: MAPBOX_LAYERS.MAPBOX_SOURCE_ID,
            paint: {
                //'circle-radius': 10,
                'circle-radius': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    bubbleSettings.bubbleSize * bubbleSettings.bubbleHoverMultiplier,
                    bubbleSettings.bubbleSize,
                ],
                'circle-color': ['case', ['boolean', ['==', ['get', 'active'], true], false], bubbleSettings.activeColor, styles.COLOR_BRAND],
            },
            filter: ['==', ['get', 'type'], 'point'],
        },
        !locationIndicatorSettings?.hideLabels ? MAPBOX_LAYERS.MAPBOX_LAYER_POINT_LABEL_ID : undefined
    );

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_FIR_LABEL_ID) &&
    !locationIndicatorSettings?.hideLabels &&
    map.addLayer({
        id: MAPBOX_LAYERS.MAPBOX_LAYER_FIR_LABEL_ID,
        type: 'symbol',
        source: MAPBOX_LAYERS.MAPBOX_SOURCE_ID,
        layout: {
            'text-field': ['get', 'name'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 1],
            'text-anchor': 'top',
            'text-allow-overlap': true,
            'text-size': 20,
        },
        paint: {
            'text-color': styles.COLOR_TEXT_MAP,
        },
        filter: ['==', ['get', 'type'], 'fir'],
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_FIR_ID) &&
    map.addLayer(
        {
            id: MAPBOX_LAYERS.MAPBOX_LAYER_FIR_ID,
            type: 'circle',
            source: MAPBOX_LAYERS.MAPBOX_SOURCE_ID,
            paint: {
                'circle-radius': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    bubbleSettings.bubbleSize * bubbleSettings.bigBubbleMultiplier * bubbleSettings.bubbleHoverMultiplier,
                    bubbleSettings.bubbleSize * bubbleSettings.bigBubbleMultiplier,
                ],
                'circle-color': styles.COLOR_BRAND,
            },
            filter: ['==', ['get', 'type'], 'fir'],
        },
        !locationIndicatorSettings?.hideLabels ? MAPBOX_LAYERS.MAPBOX_LAYER_FIR_LABEL_ID : undefined
    );

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !map.getLayer(MAPBOX_LAYERS.MAPBOX_LAYER_AREA_ID) &&
    map.addLayer(
        {
            id: MAPBOX_LAYERS.MAPBOX_LAYER_AREA_ID,
            type: 'fill',
            source: MAPBOX_LAYERS.MAPBOX_SOURCE_ID,
            paint: {
                'fill-color': '#888888',
                'fill-opacity': 0.4,
            },
            filter: ['==', ['get', 'type'], 'area'],
        },
        MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID
    );
};

let hoveredStateId: string | undefined | number;

/**
 *
 * @param setModalState
 * @param navigate
 * @param map
 * @description Handler for onclik, hover etc on map location indicators
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addZonesListerners = (setModalState: (obj: TNotamModalState) => void, navigate: NavigateFunction, map?: mapboxgl.Map) => {
    if (map) {
        // When click on a notam, place a marker
        map.on('click', MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_FILL_ID, (evt) => {
            addMarker(map, {lat: evt.lngLat.lat, long: evt.lngLat.lng});
        });

        //Cursor change for zones enter
        map.on('mousemove', MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_FILL_ID, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        //Cursor change for zones leave
        map.on('mouseleave', MAPBOX_LAYERS.MAPBOX_LAYER_NOTAM_POLYGON_FILL_ID, () => {
            map.getCanvas().style.cursor = 'default';
        });

        // When the marker has been added, show the modal with notams at the specific location
        map.on('markerAdded', (evt: TMarkerAddedEvent) => {
            setModalState({visible: true, lngLat: evt.latLng});
        });

        // When clicked, check for any features clicked and set modalState
        map.on('click', MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID, (evt) => {
            const features = evt.features;
            if (features) {
                removeMarker();
                setModalState({
                    icao: features[0].properties?.name,
                    visible: true,
                    link: features[0].properties?.link,
                });
            }
        });

        map.on('mousemove', MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID, (evt) => {
            map.getCanvas().style.cursor = 'pointer';
            const features = evt.features;
            if (features) {
                hoveredStateId = features[0].id;
                map.setFeatureState(
                    {
                        ...features[0],
                    },
                    {
                        hover: true,
                    }
                );
            }
        });
        map.on('mouseleave', MAPBOX_LAYERS.MAPBOX_LAYER_POINT_ID, () => {
            map.getCanvas().style.cursor = 'default';
            if (hoveredStateId) {
                map.setFeatureState(
                    {
                        source: MAPBOX_LAYERS.MAPBOX_SOURCE_ID,
                        id: hoveredStateId,
                    },
                    {
                        hover: false,
                    }
                );
                hoveredStateId = undefined;
            }
        });

        map.on('click', MAPBOX_LAYERS.MAPBOX_LAYER_FIR_ID, (evt) => {
            const features = evt.features;
            if (features) {
                const link = features[0].properties?.link;
                link && navigate(link);
            }
        });

        map.on('mousemove', MAPBOX_LAYERS.MAPBOX_LAYER_FIR_ID, (evt) => {
            map.getCanvas().style.cursor = 'pointer';
            const features = evt.features;
            if (features) {
                hoveredStateId = features[0].id;
                map.setFeatureState(
                    {
                        ...features[0],
                    },
                    {
                        hover: true,
                    }
                );
            }
        });
        map.on('mouseleave', MAPBOX_LAYERS.MAPBOX_LAYER_FIR_ID, () => {
            map.getCanvas().style.cursor = 'default';
            if (hoveredStateId) {
                map.setFeatureState(
                    {
                        source: MAPBOX_LAYERS.MAPBOX_SOURCE_ID,
                        id: hoveredStateId,
                    },
                    {
                        hover: false,
                    }
                );
                hoveredStateId = undefined;
            }
        });
    }
};

import { INotam } from '@naviair-utm/node-shared-interfaces';
import moment from 'moment';
import React from 'react';
import { styles } from '../../Styles/styles';
import { newMessageTag, tag } from '../InfoPage/TableCol/helpers';
import { IAftnMessageFC } from './AftnMessages';
import './styles.scss';

export const NotamHeader: React.FC<IAftnMessageFC<INotam>> = (props) => (
	<div className={'aftnRow aftnHeader notamHeader'} key={`notam_header_${props.index}`}>
		<div className={'aftnCol bold'}>{`${props.message.parsedMessage.rawFields?.a ?? ''}`}</div>
		<div className={'aftnCol bold'}>{`${props.message.parsedMessage.qualification?.location ?? ''}`}</div>
		<div className={'aftnCol bold'}>{`${props.message.parsedMessage.header?.id ?? ''}`}</div>

		<div className={'aftnCol'}>{newMessageTag(props.message.receivedDateTime)}</div>
		<div className={'aftnCol'}>
			{tag(
				props.message.parsedMessage.rawFields?.['b'] ?? '',
				undefined,
				props.message.validity?.start && moment(new Date()).unix() < moment(new Date(props.message.validity.start)).unix() ? styles.COLOR_YELLOW : undefined
			)}
		</div>
		<div className={'aftnCol'}>
			{tag(
				props.message.parsedMessage.rawFields?.['c'] ?? 'PERM',
				props.message.validity?.end && moment(new Date()).unix() > moment(new Date(props.message.validity.end)).unix()
			)}
		</div>
	</div>
);

export const NotamHeaderMobile: React.FC<IAftnMessageFC<INotam>> = (props) => (
	<div className={'aftnRow mobile aftnHeader notamHeader'} key={`notam_header_mobile_${props.index}`}>
		<div className={'aftnCol mobile bold'}>
			<div>{`${props.message.parsedMessage.rawFields?.a ?? ''}`}</div>
			{newMessageTag(props.message.receivedDateTime)}
		</div>
		<div className={'aftnCol mobile bold'}>
			<div>{`${props.message.parsedMessage.qualification?.location ?? ''}`}</div>
			{tag(
				props.message.parsedMessage.rawFields?.['b'] ?? '',
				undefined,
				props.message.validity?.start && moment(new Date()).unix() < moment(new Date(props.message.validity.start)).unix() ? styles.COLOR_YELLOW : undefined
			)}
		</div>
		<div className={'aftnCol mobile bold'}>
			<div>{`${props.message.parsedMessage.header?.id ?? ''}`}</div>
			{tag(
				props.message.parsedMessage.rawFields?.['c'] ?? 'PERM',
				props.message.validity?.end && moment(new Date()).unix() > moment(new Date(props.message.validity.end)).unix()
			)}
		</div>
	</div>
);

export const NotamContent: React.FC<IAftnMessageFC<INotam>> = (props) => {
	return (
		<React.Fragment key={`notam_${props.index}`}>
			{props.message.parsedMessage.rawFields &&
				Object.keys(props.message.parsedMessage.rawFields).map((field, index) => {
					if (!['HEADER', 'Q', 'A'].includes(field.toLocaleUpperCase())) {
						return (
							<div key={`${props.message.parsedMessage.header?.id}${field}${index}`} className={'aftnRow aftnContent noPadding'}>
								{/* eslint-disable-next-line react/forbid-dom-props */}
								<div className={'aftnCol bold'} style={{ width: 48, maxWidth: 48 }}>{`${field.toLocaleUpperCase()}) `}</div>
								<div className={'aftnCol italic'}>{props.message.parsedMessage.rawFields?.[field]}</div>
							</div>
						);
					}
				})}
		</React.Fragment>
	);
};

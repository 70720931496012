/* eslint-disable @typescript-eslint/naming-convention */
import { IConfiguration } from '@naviair-gl/node-shared-interfaces';
import { TAftnResponse } from '@naviair-utm/node-shared-interfaces';
import { fetchApi } from '../fetchApi';

export type TIcaoLocation = { indicator: string; iata: string; name: string; fir: string; location: [string, string]; country: string };
// eslint-disable-next-line @typescript-eslint/no-unused-vars

/** A hook that contains methods to query backend services such as Configuration, AFTN and more. */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const backendApiHook = () => {
	/**
	 * Load configuration for the current domain
	 * @param domain The domain string
	 * @returns Complete configuration @see IConfiguration
	 */
	const getConfiguration = (domain: string): Promise<IConfiguration> => {
		return new Promise((resolve, reject) => {
			fetchApi<IConfiguration>(
				`/${process.env.REACT_APP_BACKEND_API_CONF_API_NAME ?? 'conf'}/${domain}`,
				'GET',
				process.env.REACT_APP_BACKEND_API_CONF_URL,
				process.env.REACT_APP_BACKEND_API_CONF_KEY
			)
				.then((result) => {
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	/**
	 * Get location indicators
	 * @param search Filter returned locations
	 * @returns Array of locations @see TIcaoLocation
	 */
	const getLocation = (search: string): Promise<TIcaoLocation[]> => {
		return new Promise((resolve, reject) => {
			fetchApi<TIcaoLocation[]>(
				`/${process.env.REACT_APP_BACKEND_API_CONF_API_NAME ?? 'conf'}/locations/${search}`,
				'GET',
				process.env.REACT_APP_BACKEND_API_CONF_URL,
				process.env.REACT_APP_BACKEND_API_CONF_KEY
			)
				.then((result) => {
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	/**
	 * Get AFTN messages for a specific ICAO code
	 * @param icaoSearch The four-letter ICAO code
	 * @returns AFTN response array @see TAftnResponse
	 */
	const getAftn = (icaoSearch: string): Promise<TAftnResponse> => {
		return new Promise((resolve, reject) => {
			fetchApi<TAftnResponse>(
				`/${process.env.REACT_APP_BACKEND_API_AFTN_API_NAME ?? 'aftn'}/search/${icaoSearch}`,
				'GET',
				process.env.REACT_APP_BACKEND_API_AFTN_URL,
				process.env.REACT_APP_BACKEND_API_AFTN_KEY
			)
				.then((result) => {
					resolve(result);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	interface ISendGridPayload {
		to: string;
		from: string;
		subject: string;
		html: string;
	}

	const sendMail = (form: ISendGridPayload): Promise<void> => {
		return new Promise((resolve, reject) => {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			fetchApi('/email/send', 'POST', process.env.REACT_APP_BACKEND_API_SENDMAIL_URL, process.env.REACT_APP_BACKEND_API_SENDMAIL_KEY, JSON.stringify(form))
				.then(() => resolve())
				.catch((err) => reject(err));
		});
	};

	return {
		getConfiguration,
		getLocation,
		getAftn,
		sendMail,
	};
};

/* eslint-enable @typescript-eslint/naming-convention */

import { IAftnMessage, INotam } from '@naviair-utm/node-shared-interfaces';
import moment from 'moment';

export const oldMessage = (time: Date | undefined, ageHours = 24): boolean => {
	if (time) {
		const messageTime = moment(time);
		const now = moment();

		return now.diff(messageTime, 'hours') > ageHours;
	} else {
		return false;
	}
};

/**
 * Sort notams by their ICAO, then start time. Used directly in a .sort() method
 * @param a First message @see IAftnMessage<INotam>
 * @param b Second message @see IAftnMessage<INotam>
 * @returns Number for sorting
 */
export const notamSort = (a: IAftnMessage<INotam>, b: IAftnMessage<INotam>): number => {
	if (a.icao && a.validity && b.icao && b.validity) {
		// Sort first by ICAO, then by validity start
		return a.icao.localeCompare(b.icao) || moment(a.validity.start).diff(moment(b.validity.start));
	} else if (a.icao && b.icao) {
		// If only ICAO is present, sort alphabetically
		return a.icao.localeCompare(b.icao);
	} else if (a.validity && b.validity) {
		// If only validity is present, sort by validity start
		return moment(a.validity.start).diff(moment(b.validity.start));
	} else {
		return 0;
	}
};

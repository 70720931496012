import { TAftnResponse } from '@naviair-utm/node-shared-interfaces';
import { atom, selector, useSetRecoilState } from 'recoil';
import { backendApiHook } from '../../Api';
const { getAftn } = backendApiHook();
import { selectorConfiguration } from '.';

/**Get aftn messages from elastic */
const selectorAftn = selector<TAftnResponse | undefined>({
	key: 'aftnSelector',
	get: async ({ get }) => {
		getAftnData(get(selectorConfiguration).settings.map.icao);
		return getAftn(get(selectorConfiguration).settings.map.icao)
			.then((data) => data)
			.catch(() => Object.assign({}));
	},
});

export const atomAftnLastUpdated = atom<Date | undefined>({
	key: 'aftnLastUpdatedAtom',
	default: undefined,
});

export const atomAftnData = atom<TAftnResponse | undefined>({
	key: 'aftnDataAtom',
	default: selectorAftn,
});

const getAftnData = (icao: string): void => {
	const setAftnDataRState = useSetRecoilState(atomAftnData);
	const setAftnLastUpdatedRState = useSetRecoilState(atomAftnLastUpdated);
	setInterval(() => {
		getAftn(icao).then((aftnData) => {
			setAftnDataRState(aftnData);
			setAftnLastUpdatedRState(new Date());
		});
	}, 60000 * 15);
};

import { atom, selector } from 'recoil';
import { EScreenType } from '@naviair-gl/node-shared-interfaces';
import { TIcaoLocation } from '../../Api';

/** What screentype is being displayed? See {@link EScreenType} */
export const atomScreenType = atom({
	key: 'appScreenType',
	default: EScreenType.DESKTOP,
});

export const atomShowMobileNav = atom({
	key: 'mobileNav',
	default: false,
});

/**Is the app in landscape mode? */
export const atomScreenLandscape = atom({
	key: 'appScreenLandscape',
	default: false,
});

/**Is the app currently loading? */
export const atomGeneralLoading = atom({
	key: 'generalLoading',
	default: true,
});

/**State for displaying CookieWarning */
export const atomShowCookie = atom({
	key: 'showCookieState',
	default: false,
});

type TDropDownTrigger = ('click' | 'hover' | 'contextMenu')[];
/**
 * Selector for returning dropdown trigger
 * If screenType.MOBILE, we should use click trigger for better responsiveness.
 * screenType.MOBILE also includes tablets.
 * Else, hover.
 * @returns See {@link TDropDownTrigger} or undefined.
 */
export const selectorDropdownTrigger = selector({
	key: 'dropdownTriggerSelector',
	get: ({ get }): TDropDownTrigger => {
		const state = get(atomScreenType);
		switch (state) {
			case EScreenType.MOBILE:
				return ['click'];
			default:
				return ['hover'];
		}
	},
});

/* Made to avoid fetching the same data twice on point hover, then notam modal show. Can be optimized. Should maybe cache all fetches on sight. */
export const latestIcaoFetch = atom<TIcaoLocation | undefined>({
	key: 'latestIcaoFetch',
	default: undefined,
});

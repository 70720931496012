import { StyleSheet, Font } from '@react-pdf/renderer';
import { styles } from '../../../Styles/styles';

/* Register Nunito font */
Font.register({
	family: 'Nunito',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/nunito/v24/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNirXAHjabf.ttf',
			fontWeight: 400,
			fontStyle: 'italic',
		},
		{
			src: 'https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshdTQ3ig.ttf',
			fontWeight: 400,
			fontStyle: 'normal',
		},
		{
			src: 'https://fonts.gstatic.com/s/nunito/v24/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmdTQ3ig.ttf',
			fontWeight: 700,
			fontStyle: 'normal',
		},
	],
});

export const pdfStyles = StyleSheet.create({
	page: {
		paddingBottom: 40,
	},
	section: {
		padding: 20,
	},
	documentContainer: {
		fontFamily: 'Nunito',
	},
	imageLogo: {
		margin: 20,
		marginTop: 50,
		marginBottom: 0,
		maxHeight: 50,
		objectFit: 'contain',
		height: 'auto',
		width: 'auto',
	},
	pageBuffer: {
		marginTop: 20,
	},
	aftnTypeHeader: {
		display: 'flex',
		flexDirection: 'row',
	},
	aftnTypeTitle: {
		textAlign: 'center',
		flex: 2,
		fontSize: 10,
		marginBottom: 10,
		padding: 5,
		color: styles.COLOR_WHITE,
		backgroundColor: '#a1a1a1',
		fontWeight: 700,
	},
	aftnTypeBuffer: {
		flex: 10,
	},
	aftnContentCotainer: {
		marginBottom: 10,
	},
	aftnHeaderContainer: {
		padding: 5,
		fontSize: 10,
		backgroundColor: '#a1a1a1',
		display: 'flex',
		flexDirection: 'row',
		textAlign: 'center',
		color: styles.COLOR_WHITE,
		fontWeight: 700,
	},
	aftnHeaderItem: {
		flex: 1,
		padding: 2.5,
	},
	aftnHeaderLeft: {
		left: 10,
		marginRight: 10,
		color: styles.COLOR_WHITE,
	},
	aftnHeaderRawField: {
		marginRight: 10,
		backgroundColor: styles.COLOR_YELLOW,
		color: styles.COLOR_WHITE,
	},
	aftnContentContainer: {
		display: 'flex',
		flexDirection: 'row',
		textAlign: 'left',
		fontSize: 9,
		paddingLeft: 40,
		paddingRight: 40,
		paddingTop: 2.5,
	},
	metarTafContentContainer: {
		display: 'flex',
		flexDirection: 'row',
		textAlign: 'left',
		fontSize: 9,
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 4,
	},
	aftnContentField: {
		flex: 1,
	},
	aftnContentTagContainer: {
		flex: 2,
	},
	aftnContentTag: {
		flex: 2,
		textAlign: 'center',
		backgroundColor: styles.COLOR_BRAND,
		color: 'white',
		padding: '2px 5px',
		minHeight: 18,
		maxHeight: 18,
	},
	aftnContentRawField: {
		flex: 10,
		fontStyle: 'italic',
		paddingRight: 10,
	},
	title: {
		marginTop: 20,
		marginBottom: 40,
		fontSize: 14,
		textAlign: 'center',
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		fontSize: 12,
		color: 'gray',
		flexDirection: 'row',
	},
	footerPageNumber: {
		textAlign: 'right',
		flex: 1,
		fontSize: 8,
		display: 'flex',
		marginRight: 10,
	},
	footerPageLeft: {
		textAlign: 'left',
		flex: 1,
		fontSize: 8,
		display: 'flex',
		marginLeft: 10,
	},
	footerPageCenter: {
		textAlign: 'center',
		flex: 4,
		fontSize: 8,
		display: 'flex',
	},
});

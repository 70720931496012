// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/naming-convention */
export const styles = {
	COLOR_BRAND: '#96AE09',
	COLOR_WHITE: '#FFF',
	COLOR_TEXT_MAP: '#33526E',
	COLOR_RED: '#CA4646',
	COLOR_YELLOW: '#FAAD14',
	COLOR_BLUE: '#0996AE',
};

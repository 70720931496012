import React, { Suspense, useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import {BrowserRouter as Router, Route, Navigate} from 'react-router-dom';
import '../src/Translation/i18n'; //load i18n integration at outermost level
import { App } from './Views';
import { Error, useComponent } from '@naviair-utm/react-shared-components';
import { InitIconLibrary } from './Utils';
import { createRoot } from 'react-dom/client';
import { init as initApm } from '@elastic/apm-rum';
import { ApmRoutes } from '@elastic/apm-rum-react';
// Imports the version from package.json
import {version as packageJSONVersion} from '../package.json';
import './styles.scss';

//Workaround for webpack-hot-middleware not refreshing on dev environment
if (module['hot']) {
	module['hot'].accept();
}

/**
 * Elastic APM
 * Distinguishes between 'production' and 'development'.
 * All metrics currently uploaded to production APM RUM server.
 **/
initApm({
	serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
	serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER,
	/* Using this approach instead of process.env.NODE_ENV
	Because we build "production" mode on dev site. (to mimic prd build as closely as possible)*/
	environment: process.env.REACT_APP_ENVIRONMENT === 'PRD' ? 'production' : 'development',
	serviceVersion: packageJSONVersion,
	active: true,
});

/**
 * Wrapper to initialize general app dependencies.
 * A necessity due to multiple briefing configurations, so we need to read Recoil value.
 * @param props
 * @returns
 */
const AppInit = () => {
	const { LoadOverlay } = useComponent();

	useEffect(() => {
		/* Import Icons */
		InitIconLibrary();

		/**
		 * Unregister old ServiceWorker(s)
		 * TODO remove in next major release as all users should have had their ServiceWorker(s) removed
		 **/
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.getRegistrations()
				.then((registrations) => {
					for (const registration of registrations) {
						registration.unregister();
					}
				})
				.catch((err) => {
					// eslint-disable-next-line no-console
					console.error('Service Worker failed to unregister:', err);
				});
		}
	}, []);
	return (
		<RecoilRoot>
			<Suspense fallback={<LoadOverlay />}>
				<Router>
					<ApmRoutes>
						<Route path={'/'} element={<Navigate to={'/app/map'} />} />
						<Route path={'/app/*'} element={<App />} />
						<Route path={'error/:errorCode'} element={<Error buttonText={'Tilbage'} errorTitle={'Beklager! - der skete en fejl'} />} />
						<Route path={'*'} element={<Navigate to={'/error/404'} />} />
					</ApmRoutes>
				</Router>
			</Suspense>
		</RecoilRoot>
	);
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<AppInit />
	</React.StrictMode>
);

import { ISigmet } from '@naviair-utm/node-shared-interfaces';
import React from 'react';
import { getSigmetId } from '../InfoPage/TableCol';
import { convertTime, tag } from '../InfoPage/TableCol/helpers';
import { IAftnMessageFC } from './AftnMessages';

export const SigmetHeader: React.FC<IAftnMessageFC<ISigmet>> = (props) => (
	<div className={'aftnRow aftnHeader'} key={`sigmet_header_${props.index}`}>
		<div className={'aftnCol bold'}>{`${props.message.icao}`}</div>
		<div className={'aftnCol bold'}>{`${getSigmetId(props.message)}`}</div>
		<div className={'aftnCol right'}>{tag(convertTime(props.message.validity?.start))}</div>
		<div className={'aftnCol right'}>{tag(convertTime(props.message.validity?.end))}</div>
	</div>
);

export const SigmetContent: React.FC<IAftnMessageFC<ISigmet>> = (props) => (
	<div className={'aftnRow aftnContent noPadding'} key={`sigmet_content_${props.index}`}>
		<div className={'aftnCol italic'}>{props.message.rawInput}</div>
	</div>
);

import React, { useEffect, useState } from 'react';
import { Button, Collapse, Divider } from 'antd';
import { Modal } from '@naviair-utm/react-shared-components';
import moment from 'moment';
import { Recoil, useRecoilValue } from '../../Recoil';
import './styles.scss';
import { AftnContent, AftnHeader } from '../AftnMessages/AftnMessages';
import { convertAftnToTable } from '../InfoPage';
import { TAftnTableData } from '../../interfaces';
import { RouteComponentProps, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TNotamModalState } from '../../Views/App/Map';
import { roundToDecimals } from '../../Utils/RoundToDecimals';
import { EScreenType } from '@naviair-utm/node-shared-interfaces';
import mapboxgl from 'mapbox-gl';
import { getNotamPolygon } from '../../Views/App/Map/mapControlHelper';
import { backendApiHook } from '../../Api';
import { notamSort } from '../../Utils';
export interface INotamModalProps extends TNotamModalState {
	routeProps: RouteComponentProps;
	mapRef?: mapboxgl.Map;
	onClose: () => void;
}

export const NotamModal: React.FC<INotamModalProps> = (props) => {
	const getAftnMessages = useRecoilValue(Recoil.Aftn.Atom);
	const [getAftnData, setAftnData] = useState<TAftnTableData | []>();
	const [t] = useTranslation();
	const configuration = useRecoilValue(Recoil.Configuration.Selector);
	const availableAftnTypes = configuration.settings.availableAftnTypes;
	const screenType = useRecoilValue(Recoil.ScreenType.Atom);
	const isMobile = screenType === EScreenType.MOBILE;

	useEffect(() => {
		const aftnMessages = computeAftnMessages();

		if (aftnMessages.length > 0) {
			aftnMessages.forEach((aftnType) => {
				if (availableAftnTypes.includes(aftnType.type)) {
					if (aftnType.type !== 'metar_taf') {
						aftnType.data.sort((a, b) => {
							const momentStartA = moment(a.validity?.start).unix();
							const momentStartB = moment(b.validity?.start).unix();
							return momentStartA - momentStartB;
						});
					}
				} else {
					// If this type is not included in the availableAftnTypes, remove all messages from array
					aftnType.data = [];
				}
			});

			// After filtering on aftnTypes, check if there is any data left
			let isEmpty = true;
			aftnMessages.forEach((aftnType) => {
				if (aftnType.data.length > 0) isEmpty = false;
			});

			// If no data is left, set data to an empty array
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			isEmpty ? setAftnData([]) : setAftnData(aftnMessages);
		}
	}, [props.icao, props.lngLat, getAftnMessages]);

	// Get aftn messages from what source available
	const computeAftnMessages = (): TAftnTableData | [] => {
		if (props.icao && getAftnMessages) {
			// If a location indicator is clicked and the icao prop is set
			return convertAftnToTable(getAftnMessages, [props.icao]);
		} else if (props.lngLat && getAftnMessages) {
			// A random location is clicked and a coordinate is set
			const features = props.mapRef?.queryRenderedFeatures(props.mapRef.project([props.lngLat.long, props.lngLat.lat]), {
				layers: ['aftn-notams-polygons-fill'],
			});
			const aftnMessages = features && getNotamPolygon(features);
			return convertAftnToTable(aftnMessages);
		} else {
			return [];
		}
	};

	const getDefaultFirstKey = () => {
		const key = getAftnData?.findIndex((aftn) => aftn.data.length > 0);
		return key ? `aftn_${key}_0` : 'aftn_0_0';
	};

	return (
		<Modal
			draggable={false}
			footer={null}
			onClose={props.onClose}
			open={props.visible}
			className={'aftn-modal-collapse' + isMobile ? 'mobile' : ''}
			width={660}
			title={<AftnModalHeader {...props} />}>
			<Collapse defaultActiveKey={[getDefaultFirstKey()]} expandIconPosition={'end'} accordion>
				{getAftnData && getAftnData.length > 0 ? (
					getAftnData.flatMap((aftnData, index) => {
						if (aftnData.data.length > 0) {
							// If notam, sort based on validity start so that the oldest messages are shown first
							if (aftnData.type === 'notam') {
								aftnData.data.sort(notamSort);
							}
							return (
								<React.Fragment key={`aftn_type_${index}`}>
									<h3 className={'aftnTitle'}>{aftnData.name}</h3>
									{aftnData.data.flatMap((aftnMessage, index1: number) => (
										<Collapse.Panel
											header={<AftnHeader message={aftnMessage} type={aftnData.type} mobile={isMobile} />}
											// eslint-disable-next-line react/forbid-component-props
											style={{ backgroundColor: '#e0e1dd' }}
											key={`aftn_${index}_${index1}`}>
											{<AftnContent message={aftnMessage} type={aftnData.type} mobile={true} index={index1} key={`aftn_content_${index}_${index1}`} />}
										</Collapse.Panel>
									))}
								</React.Fragment>
							);
						}
					})
				) : (
					<p>{t('Ingen aktive notams')}</p>
				)}
			</Collapse>
		</Modal>
	);
};

interface IAftnModalHeader extends TNotamModalState {
	routeProps: RouteComponentProps;
}

/**
 * Generate header for the modal using the modalTitle function below and adding a button if relevant
 */
const AftnModalHeader: React.FC<IAftnModalHeader> = (props) => {
	const [t] = useTranslation();
	const latestFetch = useRecoilValue(Recoil.LatestIcaoFetch.Atom);
	const [icaoTitle, setIcaoTitle] = useState<string>();
	const { getLocation } = backendApiHook();
	const navigate = useNavigate();

	/* Generate notam modal icao title */
	useEffect(() => {
		if (props.icao && props.icao !== latestFetch?.indicator) {
			getLocation(props.icao).then((res) => {
				/* In case of multiple results, use the one matching the icao code. */
				const result = res.find((res) => props.icao && res.indicator.includes(props.icao));
				setIcaoTitle(result?.name);
			});
		} else {
			/* If icao was already fetched from map hover, dont fetch again - use cached value. */
			setIcaoTitle(latestFetch?.name);
		}
	}, []);

	/**
	 * Get the relevant title for the modal
	 * @param props The modal state @see TNotamModalState
	 * @returns String
	 */
	const modalTitle = (props: TNotamModalState) => {
		if (props.title) {
			// If a title is given directly, use this
			return props.title;
		} else if (props.icao) {
			// If an ICAO code is given (when click on a location indicator), use this
			return (
				<>
					<b>{props.icao}</b>
					<Divider type={'vertical'} />
					<span>{icaoTitle ? icaoTitle : ''}</span>
				</>
			);
		} else if (props.lngLat) {
			// If coordinates are given, use these
			return t('Notams ved') + ': ' + roundToDecimals(props.lngLat.lat, 4) + ', ' + roundToDecimals(props.lngLat.long, 4);
		} else {
			// If nothing is provided, return an empty string
			return '';
		}
	};

	return (
		<>
			<div className={'modalTitle'}>{modalTitle({ ...props })}</div>
			{props.link !== undefined && (
				<Button
					type={'primary'}
					className={'modalLinkButton'}
					onClick={() => {
						props.link && navigate(props.link);
					}}>
					{t('Se fuld side')}
				</Button>
			)}
		</>
	);
};

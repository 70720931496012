import React, { createRef, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState, Recoil } from '../../Recoil';
import { Link } from 'react-router-dom';
import { useComponent, IDrawerRef } from '@naviair-utm/react-shared-components';
import { EScreenType } from '@naviair-gl/node-shared-interfaces';
import { Divider, Tooltip } from 'antd';
import './styles.scss';
import { useTranslation } from 'react-i18next';
export interface IMenuContentChildren {
	/* The title of the menu content */
	title: string;
	/* Link url */
	link?: string;
	/* The badge count */
	count?: number; // should probably be some kind of array so we can map and match names
	/* If the window should be opened externally */
	external?: boolean;
}

export interface IMenuContent {
	/* Set content title */
	title: string;
	/* Set content link  */
	link?: string;
	tooltip?: string;
	/* Open window externally */
	external?: boolean;
	/* Content of the content */
	content?: IMenuContentChildren[];
}
const MenuContent: React.FC<IMenuContent> = (props) => {
	const [content, setContent] = useState<IMenuContentChildren[]>();
	const setMobileNav = useSetRecoilState(Recoil.MobileNav.atom);

	/* Set content on component load */
	useEffect(() => {
		setContent(props.content);
	}, []);

	const onClick = (link?: string, external?: boolean) => {
		setMobileNav(false);
		external && window.open(link);
	};

	return (
		<>
			<div className={'content-div'}>
				<Tooltip title={props.tooltip} placement={'right'}>
					{!props.link ? (
						<h4 className={'menuTitle'}>{props.title.toUpperCase()}</h4>
					) : (
						<Link onClick={() => onClick(props.link, props.external)} className={!props.link ? 'disabled' : ''} to={props.external ? '#' : props.link}>
							<h4 className={'menuTitle'}>{props.title.toUpperCase()}</h4>
						</Link>
					)}
				</Tooltip>
				{content?.map((obj: IMenuContentChildren, index: number) => (
					<div key={`menuContainer_${index}`}>
						<Link onClick={() => onClick(obj.link, obj.external)} to={obj.external ? '#' : obj.link ?? ''}>
							<div className={'content-body'}>
								<div className={'left'}>{obj.title}</div>
							</div>
						</Link>
					</div>
				))}
			</div>
			<Divider className={'divider'} />
		</>
	);
};

interface ISideMenu {
	/* The menu content from configurations */
	content?: IMenuContent[];
	/* Set if client is on a mobile device */
	mobile?: boolean;
	/* Show mobileNav */
	mobileNav?: boolean;
}

/**
 * ## Menu
 * The menu component
 * @param props @see IMenu
 * @returns An interactive menu component
 **/
export const SideMenu: React.FC<ISideMenu> = (props) => {
	const drawerRef = createRef<IDrawerRef>();
	const screenTypeS = useRecoilValue(Recoil.ScreenType.Atom);
	const { Drawer } = useComponent();
	const setMobileNav = useSetRecoilState(Recoil.MobileNav.atom);
	const [t] = useTranslation();

	const visible = screenTypeS === EScreenType.DESKTOP ? true : props.mobileNav;

	return (
		<Drawer
			open={visible}
			renderInDom
			closable={!(screenTypeS === EScreenType.DESKTOP)}
			mask={!(screenTypeS === EScreenType.DESKTOP)}
			maskClosable={!(screenTypeS === EScreenType.DESKTOP)}
			onClose={() => setMobileNav(false)}
			placement={'left'}
			className={screenTypeS === EScreenType.DESKTOP ? 'sideMenu' : props.mobileNav ? 'sideMenuMobile' : 'hidden'}
			zIndex={2} // Assuming we have same component composition as Naviair UTM.
			ref={drawerRef}>
			<div className={screenTypeS === EScreenType.DESKTOP ? 'menuBody' : 'menuBodyMobile'}>
				{props.content?.map((obj: IMenuContent, index: number) => (
					<MenuContent key={`menuContent_${index}`} {...obj} tooltip={obj.tooltip ? t(obj.tooltip) : undefined}></MenuContent>
				))}
			</div>
		</Drawer>
	);
};

import { IAftnMessage } from '@naviair-utm/node-shared-interfaces';
import { ColumnsType } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { AirepCols, MetarTafCols, NotamCols, SigmetCols, SnowtamCols } from '.';
import { MetarTafColsMobile } from './MetarTaf';
import { NotamColsMobile } from './Notam';

export const tableCol: { [key: string]: { cols: ColumnsType<IAftnMessage>; expand?: ExpandableConfig<IAftnMessage> } } = {
	notam: {
		cols: NotamCols as ColumnsType<IAftnMessage>,
	},
	// eslint-disable-next-line @typescript-eslint/naming-convention
	metar_taf: {
		cols: MetarTafCols as ColumnsType<IAftnMessage>,
	},
	sigmet: {
		cols: SigmetCols as ColumnsType<IAftnMessage>,
	},
	airep: {
		cols: AirepCols as ColumnsType<IAftnMessage>,
	},
	snowtam: {
		cols: SnowtamCols as ColumnsType<IAftnMessage>,
	},
};

export const tableColMobile: { [key: string]: { cols: ColumnsType<IAftnMessage>; expand?: ExpandableConfig<IAftnMessage> } } = {
	notam: {
		cols: NotamColsMobile as ColumnsType<IAftnMessage>,
	},
	// eslint-disable-next-line @typescript-eslint/naming-convention
	metar_taf: {
		cols: MetarTafColsMobile as ColumnsType<IAftnMessage>,
	},
	sigmet: {
		cols: SigmetCols as ColumnsType<IAftnMessage>,
	},
	airep: {
		cols: AirepCols as ColumnsType<IAftnMessage>,
	},
	snowtam: {
		cols: SnowtamCols as ColumnsType<IAftnMessage>,
	},
};

import { Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EBackgroundLayerType } from '../../interfaces';
import './styles.scss';
import { EScreenType } from '@naviair-utm/node-shared-interfaces';

type TMapLayerProps = {
	screenType: EScreenType;
	selectableLayers: TMapLayer[];
	changeEvent: (layer: EBackgroundLayerType) => void;
};

export type TMapLayer = {
	type: EBackgroundLayerType;
	icon: string;
	label: string;
};

export const MapLayerSelector = (props: TMapLayerProps) => {
	const [t] = useTranslation();
	const [selectedLayer, setSelectedLayer] = useState<EBackgroundLayerType>(EBackgroundLayerType.LYST);

	const changeHandler = (event: EBackgroundLayerType) => {
		setSelectedLayer(event);
		props.changeEvent(event);
	};

	return (
		<div className={props.screenType === EScreenType.DESKTOP ? 'mapLayerContainer' : 'mapLayerContainerMobile'}>
			<Select
				placement={'topLeft'}
				bordered={false}
				onChange={changeHandler}
				defaultValue={selectedLayer}
				value={selectedLayer}
				className={'selectContainer'}
				labelInValue={false}>
				{props.selectableLayers.map((item) => (
					<Select.Option value={item.type} key={item.label} className={'selectOption'}>
						<img src={item.icon} className={'selectOptionImage'} />
						<p className={'selectOptionText'}>{t(item.label)}</p>
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { styles } from '../../../Styles/styles';
import { oldMessage } from '../../../Utils';

export const tag = (stamp: string, expired?: boolean, color?: string): JSX.Element => {
	return (
		<Tag color={tagColor(expired, color)} className={'expirationTag'}>
			{' '}
			{stamp}{' '}
		</Tag>
	);
};

export const newMessageTag = (timestamp: Date | undefined): JSX.Element => {
	if (timestamp && !oldMessage(timestamp)) {
		return (
			<Tag color={styles.COLOR_BLUE} className={'newTag'}>
				{'NEW'}
			</Tag>
		);
	} else {
		return <>&nbsp;</>;
	}
};

export const convertTime = (date: Date | undefined | null): string => (date ? moment.utc(date).format('YYMMDDHHmm') : '');

export const tagColor = (expired?: boolean, color?: string): string => {
	if (expired) {
		return styles.COLOR_RED;
	} else if (color) {
		return color;
	} else {
		return styles.COLOR_BRAND;
	}
};

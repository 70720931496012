import React, { SetStateAction, useState } from 'react';
import { Recoil, useRecoilValue } from '../../Recoil';
import Logo from '../../Assets/Brand/Logo.svg';
import LogoMobile from '../../Assets/Brand/Logo_small.svg';
import './styles.scss';
import { EScreenType } from '@naviair-gl/node-shared-interfaces';
import { useComponent, ISuggests, Icon, EIconTypes } from '@naviair-utm/react-shared-components';
import { backendApiHook } from '../../Api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { faBars } from '@naviair-utm/react-fortawesome/icons/regular/faBars';

interface IHeader {
	isMobile: { showNav: boolean; setShowNav: React.Dispatch<SetStateAction<boolean>> };
}

export const Header: React.FC<IHeader> = (props) => {
	const { Header, Search } = useComponent();
	const screenTypeS = useRecoilValue(Recoil.ScreenType.Atom);
	const [getSuggestsState, setSuggestsState] = useState<ISuggests[]>([]);
	const { getLocation } = backendApiHook();
	const navigate = useNavigate();
	const [t] = useTranslation();

	const handleSubmit = (items: string | string[] | undefined) => {
		if (items) {
			if (typeof items === 'string') {
				// Single item
				navigate(`/app/aftn?icao=${items}`);
				setSuggestsState([]); //clear suggest list for next search
			} else {
				// Multiple items
				navigate(`/app/aftn?icao=${items.map((item) => item.toUpperCase()).join(',')}`);
				//  Will not clear so that the use can edit just one element if they want so
			}
		}
	};

	const handleSearchChange = (search?: string) => {
		if (search && search.length > 0) {
			getLocation(search)
				.then((locations) => {
					const parsedLocations: ISuggests[] = locations.map((location) => {
						return {
							key: location.indicator,
							title: location.indicator,
							description: location.name,
							tags: [location.name.includes('FIR') ? t('FIR') : t('AIRPORT')],
						};
					});

					if (parsedLocations.length > 0 && search.length < 4) {
						//Sets data into first item in array
						parsedLocations.unshift({ key: search.toUpperCase(), title: `${search.toUpperCase()}*` });
					}
					setSuggestsState(parsedLocations);
				})
				.catch(() => setSuggestsState([]));
		} else {
			setSuggestsState([]);
		}
	};

	return (
		<Header
			logo={screenTypeS === EScreenType.MOBILE ? <LogoMobile /> : <Logo />}
			left={
				<>
					{screenTypeS === EScreenType.MOBILE ? (
						<Button className={'burgerBtn'} onClick={() => props.isMobile.setShowNav(!props.isMobile.showNav)} type={'link'} shape={'circle'} size={'large'}>
							<Icon name={'bars'} icon={faBars} type={EIconTypes.REGULAR} />
						</Button>
					) : (
						<></>
					)}
					<Search
						className={'searchBtn'}
						suggests={getSuggestsState}
						//onChange={handleSearchChange}
						onSearch={handleSearchChange}
						placeholder={t('Søg ICAO')}
						onSubmit={handleSubmit}
						//onResultClick={() => null}
						key={'search-input'}
						multiple={true}
						allowFreeText={true}
						mobile={screenTypeS === EScreenType.MOBILE}
					/>
				</>
			}
			right={
				<>
					{/* TODO: Comment out until final implementation
					<LanguageDropdown title={t('Skift sprog')} />
					*/}
				</>
			}
		/>
	);
};

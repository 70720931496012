import React, { ReactNode } from 'react';
import { EScreenType } from '@naviair-gl/node-shared-interfaces';
import './styles.scss';

interface IMapButtons {
	screenType: EScreenType;
	children?: ReactNode;
}

export const MapButtons: React.FC<IMapButtons> = (props) => {
	return (
		<div className={`mapButtons ${props.screenType}`}>
			{props.children}
		</div>
	);
};

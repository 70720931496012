import React from 'react';
import { useParams } from 'react-router-dom';
import { IconName, ISubmitFormResponse, useComponent } from '@naviair-utm/react-shared-components';
import { EScreenType, IConfiguration, IPage } from '@naviair-gl/node-shared-interfaces';
import { useRecoilValue } from 'recoil';
import { Recoil } from '../../Recoil';
import './styles.scss';

export interface IPageViewWrapperProps {
	onLoadFinish: () => void;
	onFormSubmit: (form: ISubmitFormResponse) => Promise<void>;
}

/**
 * Page View Wrapper to handle React Router V6
 */
export const PageViewWrapper: React.FC<IPageViewWrapperProps> = (props) => {
	//Get configuration
	const configuration: IConfiguration = useRecoilValue(Recoil.Configuration.Selector);
	const screenType = useRecoilValue(Recoil.ScreenType.Atom);
	const getLanguage = useRecoilValue(Recoil.Language.Atom);
	const { PageView } = useComponent();
	const { pageKey } = useParams();

	if (pageKey) {
		return (
			<div className={`${screenType === EScreenType.MOBILE ? 'documentMobile' : 'document'}`}>
				<PageView
					page={{
						...(configuration.pages[getLanguage][pageKey] as IPage<IconName>),
						name: configuration.pages[getLanguage][pageKey].title,
					}}
					onFormSubmit={props.onFormSubmit}
					documents={configuration.documents}
					onLoad={props.onLoadFinish}
				/>
			</div>
		);
	} else {
		//TODO: Implement no page data page, maybe just redirect
		props.onLoadFinish();
		return <>{'INgen data'}</>;
	}
};

import { fortawesome } from '@naviair-utm/react-fortawesome';
import { faBars } from '@naviair-utm/react-fortawesome/icons/regular/faBars';
import { faBadgeCheck } from '@naviair-utm/react-fortawesome/icons/light/faBadgeCheck';
import { faMailbox } from '@naviair-utm/react-fortawesome/icons/light/faMailbox';
import { faQuestionCircle } from '@naviair-utm/react-fortawesome/icons/light/faQuestionCircle';
import { faArrowLeft } from '@naviair-utm/react-fortawesome/icons/solid/faArrowLeft';
import { faDownload } from '@naviair-utm/react-fortawesome/icons/solid/faDownload';
import { faRotateRight } from '@naviair-utm/react-fortawesome/icons/solid/faRotateRight';
import { faExclamationCircle } from '@naviair-utm/react-fortawesome/icons/light/faExclamationCircle';

/**
 * Add imported icons in project here, or use Icon component
 **/
export const InitIconLibrary = (): void =>
	fortawesome.library.add(faBadgeCheck, faMailbox, faQuestionCircle, faArrowLeft, faDownload, faExclamationCircle, faBars, faRotateRight);

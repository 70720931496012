/* eslint-disable react/forbid-component-props */
import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import { formatDate } from '../../../Utils';
import { IAftnMessage, IAirep, IMetar, INotam, ISigmet, ISnowtam, ITaf } from '@naviair-utm/node-shared-interfaces';
import moment from 'moment';
import { styles } from '../../../Styles/styles';
import { pdfStyles } from './styles';
import { TAftnTableData, TMetarTaf } from '../../../interfaces';
import Logo from '../../../Assets/Brand/logo512.png';
import { EAftnTypes } from '@naviair-gl/node-shared-interfaces';
import { getValidityTag } from '../../AftnMessages/MetarTaf';
import { convertTime } from '../TableCol/helpers';

interface ITableToPdf {
	title: string;
	data: [] | TAftnTableData;
	availableAftnTypes: EAftnTypes[];
}

/**
 * ## TableToPdf
 * Converts AFTN Table of @type TAftnTableData to a react-pdf component
 **/
export const TableToPdf: React.FC<ITableToPdf> = (props) => {
	return (
		<Document title={props.title} creator={'Naviair UTM'} producer={'Naviair UTM'}>
			<Page size={'A4'} style={pdfStyles.page}>
				<View style={pdfStyles.documentContainer}>
					{/* HEADER START */}
					<Image style={pdfStyles.imageLogo} src={Logo} />
					<Text style={pdfStyles.title}> {props.title} </Text>
					{/* HEADER END */}
					{/* Buffer to make margin on each side that is not frontpage. */}
					<View render={({ pageNumber }) => pageNumber !== 1 && <View style={pdfStyles.pageBuffer}></View>} fixed></View>
					{/*For each table item */}
					{props.data
						/* Only print included AFTN types */
						.filter((entry) => props.availableAftnTypes.includes(entry.type))
						.map((aftnData, i) => {
							if (aftnData.data.length !== 0) {
								return (
									<View key={`${aftnData.name}_${i}`} style={pdfStyles.section}>
										<View style={pdfStyles.aftnTypeHeader}>
											<Text style={pdfStyles.aftnTypeTitle}>{aftnData.name.toUpperCase()}</Text>
											<Text style={pdfStyles.aftnTypeBuffer}> </Text>
										</View>
										{aftnData.type === EAftnTypes.AIREP && <></>}
										{aftnData.type === EAftnTypes.SIGMET && <></>}
										{aftnData.type === EAftnTypes.METAR_TAF && <MetarTafPdf data={aftnData.data as TMetarTaf[]} />}
										{aftnData.type === EAftnTypes.NOTAM && <NotamPdf aftnArray={aftnData.data as IAftnMessage<INotam>[]} />}
										{aftnData.type === EAftnTypes.SNOWTAM && <SnowtamPdf aftnArray={aftnData.data as IAftnMessage<ISnowtam>[]} />}
									</View>
								);
							}
						})}
					<View style={pdfStyles.footer} fixed>
						<View style={pdfStyles.footerPageNumber}>
							<Text fixed>{formatDate(new Date())}</Text>
						</View>
						<View style={pdfStyles.footerPageCenter}></View>
						<View style={pdfStyles.footerPageLeft}>
							<Text render={({ pageNumber, totalPages }) => `${pageNumber} of ${totalPages}`} fixed />
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};
interface IMetarTafPdf {
	data: TMetarTaf[];
}

const MetarTafPdf: React.FC<IMetarTafPdf> = (props) => (
	<>
		{props.data.map((metarTaf, n) => (
			<View key={`metarTafContainer_${n}`}>
				{/* Header */}
				<View style={pdfStyles.aftnHeaderContainer} key={`metarTafHeader_${n}`}>
					<Text style={pdfStyles.aftnHeaderLeft}>{metarTaf.icao}</Text>
				</View>
				{metarTaf.metar.map((data, i) => (
					<View key={`metarHeader_${data.icao}_${n}_${i}`} style={pdfStyles.aftnContentCotainer} wrap={false}>
						<View style={pdfStyles.metarTafContentContainer}>
							<Text style={pdfStyles.aftnContentField}>{data.type}</Text>
							<Text style={pdfStyles.aftnContentRawField}>{data.rawInput}</Text>
							<View style={pdfStyles.aftnContentTagContainer}>
								<Text
									style={{
										...pdfStyles.aftnContentTag,
										backgroundColor: getValidityTag(data).valid ? styles.COLOR_BRAND : styles.COLOR_RED,
									}}>
									{getValidityTag(data).text}
								</Text>
							</View>
						</View>
					</View>
				))}
				{metarTaf.taf.map((data, i) => (
					<View key={`tafHeader_${data.icao}_${n}_${i}`} style={pdfStyles.aftnContentCotainer} wrap={false}>
						<View style={pdfStyles.metarTafContentContainer}>
							<Text style={pdfStyles.aftnContentField}>{data.type}</Text>
							<Text style={pdfStyles.aftnContentRawField}>{data.rawInput}</Text>
							<View style={pdfStyles.aftnContentTagContainer}>
								<Text
									style={{
										...pdfStyles.aftnContentTag,
										backgroundColor: getValidityTag(data).valid ? styles.COLOR_BRAND : styles.COLOR_RED,
									}}>
									{getValidityTag(data).text}
								</Text>
							</View>
						</View>
					</View>
				))}
			</View>
		))}
	</>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IAftnPDF<T = INotam | IMetar | ITaf | IAirep | ISigmet | ISnowtam> {
	aftnArray: IAftnMessage<T>[];
}

const NotamPdf: React.FC<IAftnPDF<INotam>> = (props) => (
	<>
		{props.aftnArray.map((data, i) => {
			return (
				<View key={`aftnHeader_${data.icao}_${i}`} style={pdfStyles.aftnContentCotainer} wrap={false}>
					{/* Header */}
					<View style={pdfStyles.aftnHeaderContainer}>
						<Text style={pdfStyles.aftnHeaderItem}>{data.parsedMessage.rawFields?.a ?? ''}</Text>
						<Text style={pdfStyles.aftnHeaderItem}>{data.parsedMessage.qualification?.location ?? ''}</Text>
						<Text style={pdfStyles.aftnHeaderItem}>{data.parsedMessage.header?.id ?? ''}</Text>
						<Text style={pdfStyles.aftnHeaderItem}></Text>
						<Text
							style={{
								...pdfStyles.aftnHeaderItem,
								...pdfStyles.aftnHeaderRawField,
								backgroundColor:
									data.validity?.start && moment(new Date()).unix() < moment(new Date(data.validity.start)).unix() ? styles.COLOR_YELLOW : styles.COLOR_BRAND,
							}}>
							{data.parsedMessage.rawFields?.['b'] ?? ''}
						</Text>
						<Text
							style={{
								...pdfStyles.aftnHeaderItem,
								...pdfStyles.aftnHeaderRawField,
								backgroundColor:
									data.validity?.end && moment(new Date()).unix() > moment(new Date(data.validity.end)).unix() ? styles.COLOR_RED : styles.COLOR_BRAND,
							}}>
							{data.parsedMessage.rawFields?.['c'] ?? 'PERM'}
						</Text>
					</View>
					{/*Content*/}
					{data.parsedMessage.rawFields &&
						Object.keys(data.parsedMessage.rawFields).map((field, index) => {
							if (!['HEADER', 'Q', 'A'].includes(field.toLocaleUpperCase())) {
								return (
									<View style={pdfStyles.aftnContentContainer} key={`${data.parsedMessage.header?.id}${field}${index}`}>
										{/* eslint-disable-next-line react/forbid-dom-props */}
										<Text style={pdfStyles.aftnContentField}>{`${field.toLocaleUpperCase()})`}</Text>
										<Text style={pdfStyles.aftnContentRawField}>{data.parsedMessage.rawFields?.[field]}</Text>
									</View>
								);
							}
						})}
				</View>
			);
		})}
	</>
);

const SnowtamPdf: React.FC<IAftnPDF<ISnowtam>> = (props) => (
	<>
		{props.aftnArray.map((data, i) => (
			<View key={`snowtam_container_${i}`}>
				{/* Header */}
				<View style={pdfStyles.aftnHeaderContainer} key={`snowtam_header_${i}`}>
					<Text style={pdfStyles.aftnHeaderItem}>{data.icao ?? ''}</Text>
					<Text
						style={{
							...pdfStyles.aftnHeaderItem,
							...pdfStyles.aftnHeaderRawField,
							backgroundColor: styles.COLOR_BRAND,
						}}>
						{convertTime(data.validity?.start)}
					</Text>
					<Text
						style={{
							...pdfStyles.aftnHeaderItem,
							...pdfStyles.aftnHeaderRawField,
							backgroundColor: styles.COLOR_BRAND,
						}}>
						{convertTime(data.validity?.end)}
					</Text>
				</View>
				{/* Content */}
				<View style={pdfStyles.aftnContentContainer} key={`snowtam_content_${i}`}>
					<Text style={pdfStyles.aftnContentField}>{`${data.rawInput.toLocaleUpperCase()})`}</Text>
				</View>
			</View>
		))}
	</>
);
/* eslint-enable react/forbid-component-props */

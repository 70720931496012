import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { EIconTypes, Icon } from '@naviair-utm/react-shared-components';
import { faLineHeight } from '@naviair-utm/react-fortawesome/icons/regular';

type TMapLayerProps = {
	defaultChecked?: boolean;
	onChange: (switched: boolean) => void;
};

export const MapHeightFilterSelector = (props: TMapLayerProps): JSX.Element => {
	const [t] = useTranslation();
	const [getChecked, setChecked] = useState<boolean>();

	const onChange = () => {
		const newState = !getChecked;
		setChecked(newState);
		props.onChange(newState);
	};

	const displayText = () => {
		return getChecked ? t('Alle') : t('< FL195');
	};

	useEffect(() => {
		setChecked(props.defaultChecked);
	}, []);

	return (
			<div className={'mapHeightFilterContainer'} onClick={() => onChange()}>
				<Icon name={'line-height'} icon={faLineHeight} type={EIconTypes.REGULAR} />
				<p>{displayText()}</p>
			</div>
		);
};
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		lng: 'da-DK',
		supportedLngs: ['da-DK', 'en-US', 'kl-GL'],
		load: 'currentOnly',
		ns: 'translations',
		defaultNS: 'translations',
		keySeparator: false, //allows the use of '.' and ':' in translations
		nsSeparator: false, //allows the use of '.' and ':' in translations
		// fallbackLng: 'da-DK',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		react: {
			bindI18n: 'languageChanged',
			useSuspense: true,
			//https://react.i18next.com/latest/i18next-instance (scroll down)
		},
	});

// eslint-disable-next-line import/no-default-export
export default i18n;

import { ILatLng, INotam, TAftnReponseType, TAftnResponse } from '@naviair-utm/node-shared-interfaces';
import mapboxgl from 'mapbox-gl';

/**
 * Add a marker to the map.
 * @param map The current map component.
 * @param latLng Coordinates of the marker.
 * @param layersArr Layers under the new marker.
 * @param lang Language(TODO: definition?)
 * @param infoboxState Show state of Infobox.
 */
let clickMarker: mapboxgl.Marker;
export const addMarker = async (map: mapboxgl.Map, latLng: ILatLng): Promise<void> => {
	try {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (clickMarker) clickMarker.remove();
		clickMarker = new mapboxgl.Marker({ color: '#333' }).setLngLat([latLng.long, latLng.lat]).addTo(map);

		//Fire event
		map.fire('markerAdded', {
			latLng: latLng,
		});
	} catch (error) {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (clickMarker) clickMarker.remove();
	}
};

/**
 * Call this function to remove any marker regardless if one is placed or not
 */
export const removeMarker = async (): Promise<void> => {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (clickMarker) clickMarker.remove();
};

/**
 * Return a TAftnResponse of only notams at a specific coordinate
 */
export const getNotamPolygon = (features: mapboxgl.MapboxGeoJSONFeature[]): TAftnResponse => {
	/**
	 * Generate notams from queryRenderedFeatures outcome.
	 * Parse necessary properties again, as layer handling seems to stringify.
	 *  */
	const notams = features.map((feature) => {
		if (feature.properties) {
			feature.properties.errors = feature.properties.errors ? JSON.parse(feature.properties.errors) : [];
			feature.properties.parsedMessage = JSON.parse(feature.properties.parsedMessage);
			feature.properties.validity = JSON.parse(feature.properties.validity);
		}
		return feature.properties;
	});

	const returnObj: TAftnResponse = [
		{ type: 'airep', data: [] },
		{ type: 'taf', data: [] },
		{ type: 'metar', data: [] },
		{ type: 'notam', data: [] },
		{ type: 'snowtam', data: [] },
		{ type: 'sigmet', data: [] },
	];

	// Assign the notams to the return object
	returnObj[3] = { type: 'notam', data: notams } as TAftnReponseType<INotam>;
	return returnObj;
};

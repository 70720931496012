/* eslint-disable @typescript-eslint/naming-convention */
import { ColumnsType } from 'antd/lib/table';
import { IAftnMessage, IAirep } from '@naviair-utm/node-shared-interfaces';

export const AirepCols: ColumnsType<IAftnMessage<IAirep>> = [
	{
		title: 'ICAO',
		dataIndex: 'icao',
		render: (_, obj) => obj.icao,
	},
	{ title: 'ICAO', dataIndex: 'icao', render: (_, obj) => obj.rawInput },
];

/* eslint-enable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/naming-convention */
import { EScreenType, IAftnMessage, IAirep, IMetar, INotam, ISigmet, ISnowtam, ITaf, TAftnResponse } from '@naviair-utm/node-shared-interfaces';
import { EIconTypes, Icon, ITableFilterRef, useComponent } from '@naviair-utm/react-shared-components';
import { pdf } from '@react-pdf/renderer';
import { Button, Divider, message, Spin as AntdSpin } from 'antd';
import moment from 'moment';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { convertAftnToTable, TableToPdf } from '.';
import { backendApiHook } from '../../Api';
import { Recoil, useRecoilValue, useSetRecoilState } from '../../Recoil';
import { notamSort } from '../../Utils/AftnUtils';
import './styles.scss';
import { getSigmetId } from './TableCol';
import { tableCol, tableColMobile } from './TableCol/TableCol';
const { getAftn } = backendApiHook();

export const InfoPage: React.FC = () => {
	const [getQueryParamsState, setQueryParamsState] = useState<{ filter?: string[]; icao?: string[] }>({});
	const configuration = useRecoilValue(Recoil.Configuration.Selector);
	const [getTypeFilterState, setTypeFilterState] = useState<string[]>(['*']);
	const [t] = useTranslation();
	const { Table, ContentLayout, TableFilter } = useComponent();
	const screenType = useRecoilValue(Recoil.ScreenType.Atom);
	const isMobile = screenType === EScreenType.MOBILE;
	const setGLoadingState = useSetRecoilState(Recoil.GeneralLoading.Atom);
	const [getLoadingState, setLoadingState] = useState<boolean>(false);
	const [getAftnState, setAftnState] = useState<TAftnResponse>();
	const tableFilterRef = createRef<ITableFilterRef>();
	const aftnRefreshRate = process.env.AFTN_REFRESH_RATE_MINUTES ?? '5'; // The refresh rate for aftn. Defaults to 5 min
	const [getLastAftnUpdate, setLastAftnUpdate] = useState<Date | undefined>();
	const [downloading, setDownloading] = useState<boolean>(false);
	const filteredAftnData = convertAftnToTable(getAftnState, getQueryParamsState.icao ?? []);
	const availableAftnTypes = configuration.settings.availableAftnTypes;
	const [searchParams] = useSearchParams();

	/* Download PDF. Targeted component should be compatible with @react-pdf/renderer */
	const download = (item: JSX.Element) => {
		setDownloading(true);
		setTimeout(() => {
			pdf(item)
				.toBlob()
				.then((pdfBlob) => {
					const downloadDate = new Date();
					const blobURL = window.URL.createObjectURL(pdfBlob);
					const tempLink = document.createElement('a');
					tempLink.href = blobURL;
					tempLink.setAttribute(
						'download',
						`${getQueryParamsState.icao ? getQueryParamsState.icao.join('_') : ''}_${moment(downloadDate).format('DDMMYYYY')}.pdf`
					);
					tempLink.click();
				})
				.catch((e) => {
					console.log(e);
					message.error('Der gik noget galt ved konvertering til PDF');
				})
				.finally(() => setDownloading(false));
		}, 1000);
	};

	//Handle set state of aftn data on new icao-code
	const loadAftn = () => {
		//TODO: COULD BE OPTIMIZED
		if (getQueryParamsState.icao && getQueryParamsState.icao.length > 0) {
			setLoadingState(true);
			Promise.all(
				getQueryParamsState.icao.map(async (icao) => {
					const data = await getAftn(icao);
					return data;
				})
			)
				.then((data) => {
					setLoadingState(false);
					setLastAftnUpdate(new Date());

					const dataObj: { [key: string]: IAftnMessage[] } = {};
					data.map((response) => {
						response.map((message) => {
							// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
							if (dataObj[message.type]) {
								dataObj[message.type] = dataObj[message.type].concat(message.data);
							} else {
								dataObj[message.type] = message.data;
							}
						});
					});

					//For filtering uniq sigmets in bulletin
					const filterUniqSigmets = (sigmets: IAftnMessage<ISigmet>[]) => {
						const uniqueIds = new Set();
						return sigmets.filter((sigmet) => {
							const id = `${sigmet.icao}~${getSigmetId(sigmet)}`;
							const isPresentInSet = uniqueIds.has(id);
							uniqueIds.add(id);
							return !isPresentInSet;
						});
					};

					setAftnState([
						{ type: 'airep', data: dataObj['airep'] as IAftnMessage<IAirep>[] },
						{ type: 'taf', data: dataObj['taf'] as IAftnMessage<ITaf>[] },
						{ type: 'metar', data: dataObj['metar'] as IAftnMessage<IMetar>[] },
						{ type: 'notam', data: dataObj['notam'] as IAftnMessage<INotam>[] },
						{ type: 'snowtam', data: dataObj['snowtam'] as IAftnMessage<ISnowtam>[] },
						{ type: 'sigmet', data: filterUniqSigmets(dataObj['sigmet'] as IAftnMessage<ISigmet>[]) },
					]);
				})
				.catch(() => {
					setLoadingState(false);
					//TODO: ANTD message
				});
		}
	};

	// Interval function to refresh aftn data without reload of page
	let intervalLoad: NodeJS.Timer;
	const refreshAftnTimer = () => {
		// Clear interval
		clearInterval(intervalLoad);

		// Load afn for new icao
		loadAftn();

		// Set new interval func if icao is defined
		if (getQueryParamsState.icao && getQueryParamsState.icao.length > 0) {
			intervalLoad = setInterval(() => {
				loadAftn();
			}, parseInt(aftnRefreshRate) * 60 * 1000);
		}
	};

	/** If the current ICAO code changes, then reload the AFTN timer with new ICAO and reset timing */
	useEffect(() => {
		refreshAftnTimer();
	}, [getQueryParamsState.icao]);

	const filters =
		filteredAftnData.length > 0
			? [
					{
						key: '*',
						translation: t('Alle'),
						count: filteredAftnData
							.flatMap((data) => {
								if (availableAftnTypes.includes(data.type)) {
									return data.data.length;
								} else {
									return 0;
								}
							})
							.reduce((acc, curDate) => acc + curDate),
					},
			  ].concat(
					filteredAftnData.flatMap((aftnData) => {
						// Filter out the types not present in the configuration
						if (availableAftnTypes.includes(aftnData.type)) {
							return { key: aftnData.type.toUpperCase(), translation: aftnData.name.toUpperCase(), count: aftnData.data.length };
						} else {
							return [];
						}
					})
			  )
			: [];

	//Handle query params
	useEffect(() => {
		//const parsedParams = queryString.parse(searchParams);
		const filter = searchParams.get('filter') ?? searchParams.get('FILTER');
		const icao = searchParams.get('icao') ?? searchParams.get('ICAO');

		setQueryParamsState({
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			filter: filter?.toUpperCase().split(','),
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			icao: icao?.toUpperCase().split(','),
		});
	}, [searchParams]);

	//Handles set of filter type
	useEffect(() => {
		if (getQueryParamsState.filter) {
			tableFilterRef.current?.setActiveItem(getQueryParamsState.filter);
		}
	}, [getQueryParamsState.filter]);

	useEffect(() => {
		setGLoadingState(false);
	}, []);

	const navigate = useNavigate();

	return (
		<>
			<ContentLayout
				title={`${getQueryParamsState.icao ? getQueryParamsState.icao.join(', ') : ''}`.toUpperCase()}
				className={screenType === EScreenType.MOBILE ? 'mobile' : ''}
				handleBackClicked={() => navigate('/app/map')}>
				<div className={screenType === EScreenType.MOBILE ? 'headerContainerMobile' : 'headerContainer'}>
					<TableFilter data={filters} onActiveChange={setTypeFilterState} ref={tableFilterRef} />
					<div>
						<div className={'buttonContainer'}>
							<Button
								className={'buttonUpdate'}
								icon={<Icon className={'buttonIcon'} name={'rotate-right'} type={EIconTypes.SOLID} />}
								type={'primary'}
								loading={getLoadingState}
								onClick={() => refreshAftnTimer()}>
								<div className={'buttonContent'}>
									<div className={'textContainer'}>{t('Opdater')}</div>
								</div>
							</Button>
							<Button
								className={'buttonDownload'}
								disabled={getLoadingState}
								loading={downloading}
								type={'primary'}
								icon={<Icon className={'buttonIcon'} name={'download'} type={EIconTypes.SOLID} />}
								onClick={() =>
									download(
										<TableToPdf
											title={`${getQueryParamsState.icao ? getQueryParamsState.icao.join(', ') : ''}`.toUpperCase()}
											data={filteredAftnData}
											availableAftnTypes={configuration.settings.availableAftnTypes}
										/>
									)
								}>
								<div className={'buttonContent'}>
									<div className={'textContainerPDF'}>{t('PDF')}</div>
								</div>
							</Button>
						</div>
						<div>
							<div>
								<div className={`lastUpdateDisclaimer ${getLoadingState ? 'transparent' : ''}`}>
									{`${t('Senest hentet')}: ${moment(getLastAftnUpdate).format('H:mm:ss')}`}
								</div>
							</div>
						</div>
					</div>
				</div>

				<Divider />

				<div className={'disclaimer'}>{t('Alle data til operationelt brug skal bekræftes hos nærmeste AIS-kontor').toUpperCase()}</div>

				{getLoadingState ? (
					<div className={'spinner'}>
						<AntdSpin />
					</div>
				) : (
					filteredAftnData.map((aftnData) => {
						if ((getTypeFilterState.includes('*') || getTypeFilterState.includes(aftnData.type.toUpperCase())) && availableAftnTypes.includes(aftnData.type)) {
							if (aftnData.data.length === 0) {
								return (
									<React.Fragment key={`table_${aftnData.type}`}>
										<h2>{aftnData.name.toUpperCase()}</h2>
										<div className={'aftnNoData'}>{'NO ITEM LISTED'}</div>
									</React.Fragment>
								);
							} else {
								// If notam, sort based on validity start so that the oldest messages are shown first
								if (aftnData.type === 'notam') {
									aftnData.data.sort(notamSort);
								}
								return (
									<Table<IAftnMessage>
										key={`table_${aftnData.type}`}
										mobile={screenType === EScreenType.MOBILE}
										className={screenType === EScreenType.MOBILE ? 'mobile' : ''}
										rowClassName={screenType === EScreenType.MOBILE ? 'mobile' : ''}
										filter={false}
										pagination={false}
										scroll={screenType !== EScreenType.MOBILE ? { x: true } : {}}
										tableName={aftnData.name.toUpperCase()}
										showHeader={false}
										data={
											aftnData.data.map((entry) => {
												return { ...entry, _id: uuid() };
											}) as IAftnMessage[]
										}
										rowKey={'_id'}
										// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
										columns={isMobile ? tableColMobile[aftnData.type]?.cols : tableCol[aftnData.type]?.cols}
									/>
								);
							}
						}
					})
				)}
			</ContentLayout>
		</>
	);
};

/* eslint-enable @typescript-eslint/naming-convention */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/naming-convention */

// Import atoms/selectors
import {
	selectorLanguage,
	atomLanguage,
	atomGeneralLoading,
	selectorConfiguration,
	atomScreenType,
	atomScreenLandscape,
	atomShowCookie,
	selectorDropdownTrigger,
	selectorConfigProvider,
	atomShowMobileNav,
	atomAftnData,
	latestIcaoFetch,
} from './States';

// Export functionality from recoil lib
export { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

// Export atoms/selectors
export const Recoil = {
	// STATES
	Language: {
		Selector: selectorLanguage,
		Atom: atomLanguage,
	},
	GeneralLoading: {
		Atom: atomGeneralLoading,
	},
	Configuration: {
		Selector: selectorConfiguration,
	},
	ScreenType: {
		Atom: atomScreenType,
	},
	ScreenLandscape: {
		Atom: atomScreenLandscape,
	},
	ShowCookie: {
		Atom: atomShowCookie,
	},
	MobileNav: {
		atom: atomShowMobileNav,
	},
	DropDownTrigger: {
		Selector: selectorDropdownTrigger,
	},
	Locale: {
		Selector: selectorConfigProvider,
	},
	Aftn: {
		Atom: atomAftnData,
	},
	LatestIcaoFetch: {
		Atom: latestIcaoFetch,
	},
};

import { IConfiguration } from '@naviair-gl/node-shared-interfaces';
import { selector } from 'recoil';
import { backendApiHook } from '../../Api';
const { getConfiguration } = backendApiHook();

/**Get configuration from db */
export const selectorConfiguration = selector({
	key: 'configuration',
	get: async ({ get }) => {
		const response: IConfiguration = await getConfiguration(get(selectorDomain));
		return response;
	},
});

/**Get current domain for use with {@link selectorConfiguration} */
const selectorDomain = selector({
	key: 'domain',
	get: async () => {
		const hostname = window.location.hostname;
		switch (hostname) {
			case undefined:
			case '':
				return 'localhost';
			default:
				return hostname;
		}
	},
});

import { EAftnTypes } from '@naviair-gl/node-shared-interfaces';
import {
	EAftnReponse,
	IAftnMessage,
	IAirep,
	IMetar,
	INotam,
	ISigmet,
	ISnowtam,
	ITaf,
	TAftnReponseType,
	TAftnResponse,
} from '@naviair-utm/node-shared-interfaces';
import moment from 'moment';
import { TAftnTableData, TMetarTaf } from '../../interfaces';

const filterAftnData = (aftnData: TAftnResponse, icaoFilter?: string[]) => {
	const filteredAftnData = aftnData.map((dataType: TAftnReponseType<unknown>) => {
		if (icaoFilter) {
			//Filter for NOTAM
			if (dataType.type === 'notam') {
				const notams = dataType.data as IAftnMessage<INotam>[];
				return (
					notams
						.filter(
							(dataEntry) =>
								icaoFilter.filter(
									(searchString) => dataEntry.parsedMessage.rawFields?.a.toUpperCase().startsWith(searchString.toUpperCase()) //aerodome match
								).length > 0
						)
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						.sort((a, b) => {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							if (a.parsedMessage.rawFields!.a === b.parsedMessage.rawFields!.a) {
								return moment(b.validity?.start).unix() - moment(a.validity?.start).unix();
							}
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							return a.parsedMessage.rawFields!.a.localeCompare(b.parsedMessage.rawFields!.a);
						})
				);
				//Filter for Other types than notam
			} else {
				return (
					dataType.data
						.filter(
							(dataEntry) =>
								icaoFilter.filter((searchString) =>
									dataEntry.icao
										?.toUpperCase()
										//SIGMET HANDLING TO SHOW ON ALL PAGES WITH FIRST TWO LETTERS
										.startsWith(dataEntry.type === 'SIGMET' ? searchString.substring(0, 1).toUpperCase() : searchString.toUpperCase())
								).length > 0
						)
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						.sort((a, b) => {
							if (a.icao === b.icao) {
								return moment(b.validity?.start).unix() - moment(a.validity?.start).unix();
							}
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							return a.icao!.localeCompare(b.icao!);
						})
				);
			}
		} else {
			return dataType.data;
		}
	});

	return {
		airep: filteredAftnData[EAftnReponse.AIREP] as IAftnMessage<IAirep>[],
		metar: filteredAftnData[EAftnReponse.METAR] as IAftnMessage<IMetar>[],
		taf: filteredAftnData[EAftnReponse.TAF] as IAftnMessage<ITaf>[],
		notam: filteredAftnData[EAftnReponse.NOTAM] as IAftnMessage<INotam>[],
		snowtam: filteredAftnData[EAftnReponse.SNOWTAM] as IAftnMessage<ISnowtam>[],
		sigmet: filteredAftnData[EAftnReponse.SIGMET] as IAftnMessage<ISigmet>[],
	};
};

const mergeMetarTaf = (metarData: IAftnMessage<IMetar>[], tafData: IAftnMessage<ITaf>[]): TMetarTaf[] => {
	const returnData: { [key: string]: TMetarTaf } = {};
	metarData.map((inMetar) => {
		if (inMetar.icao) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (!returnData[inMetar.icao]) {
				returnData[inMetar.icao] = {
					icao: inMetar.icao,
					metar: [],
					taf: [],
				};
			}
			returnData[inMetar.icao].metar.push(inMetar);
		}
	});

	tafData.map((inTaf) => {
		if (inTaf.icao) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (!returnData[inTaf.icao]) {
				returnData[inTaf.icao] = {
					icao: inTaf.icao,
					metar: [],
					taf: [],
				};
			}
			returnData[inTaf.icao].taf.push(inTaf);
		}
	});

	return Object.keys(returnData).map((key) => {
		const data = returnData[key];
		return { icao: key, metar: data.metar, taf: data.taf };
	});
};

export const convertAftnToTable = (aftnData?: TAftnResponse, icaoFilter?: string[]): TAftnTableData | [] => {
	if (aftnData) {
		const filteredAftnReponse = filterAftnData(aftnData, icaoFilter);
		const dataMetarTaf = mergeMetarTaf(filteredAftnReponse.metar, filteredAftnReponse.taf);

		return [
			{ type: EAftnTypes.AIREP, name: 'Airep', data: filteredAftnReponse.airep },
			{ type: EAftnTypes.SIGMET, name: 'Sigmet', data: filteredAftnReponse.sigmet },
			{ type: EAftnTypes.METAR_TAF, name: 'Metar/Taf', data: dataMetarTaf },
			{ type: EAftnTypes.NOTAM, name: 'Notam', data: filteredAftnReponse.notam },
			{ type: EAftnTypes.SNOWTAM, name: 'Snowtam', data: filteredAftnReponse.snowtam },
		];
	} else {
		return [];
	}
};

/**
 * Fetches a service deployed on the backend.
 *
 * `Do not call this directly for production. Instead create a hook in the Hooks folder, which utilizes this method.`
 *
 * @example fetchApi('/conf/{domain}').then(...);
 * @param serviceMethod the service to append to the backend URL: ENV variable REACT_APP_BACKEND_API_URL
 * @param method HTTP request method. Currently supports POST, GET, DELETE. Default: GET.
 * @param body body to include in a POST request.
 * @returns response from API as a promise.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const fetchApi = async <T>(
	serviceMethod: string,
	method: 'POST' | 'GET' | 'DELETE' = 'GET',
	backendApiUrl?: string,
	backendApiKey?: string,
	body?: BodyInit,
	local?: boolean
): Promise<T> => {
	return new Promise((resolve, reject) => {
		if (serviceMethod) {
			const endpoint = local ? serviceMethod : `${backendApiUrl ?? process.env.REACT_APP_BACKEND_API_URL}${serviceMethod}`;
			const settings: RequestInit = {
				method: method,
				headers: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					Accept: 'application/json',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'Content-type': 'application/json',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'Ocp-Apim-Subscription-Key': backendApiKey ?? process.env.REACT_APP_BACKEND_API_KEY ?? '',
				},
				body: body,
			};
			try {
				fetch(endpoint, settings).then((fetchRespone) => {
					fetchRespone.json().then((responseData) => resolve(responseData));
				});
			} catch (error) {
				reject(error);
			}
		} else {
			reject('Error, no endpoint defined');
		}
	});
};

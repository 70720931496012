import { EScreenType, IConfiguration } from '@naviair-gl/node-shared-interfaces';
import { ISubmitFormResponse, useComponent } from '@naviair-utm/react-shared-components';
import { notification as antdNotification } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { backendApiHook } from '../../Api';
import { Header, InfoPage, PageViewWrapper, SideMenu } from '../../Components';
import { Recoil, useRecoilState, useRecoilValue, useSetRecoilState } from '../../Recoil';
import { capitalizeFirst } from '../../Utils';
import { Map } from './Map';
import './styles.scss';
// import { useNavigate } from 'react-router-dom';
const { sendMail } = backendApiHook();

export const App: React.FC = () => {
	const { MainLayout, MaintenanceView } = useComponent();
	const [showMobileNav, setShowMobileNav] = useRecoilState<boolean>(Recoil.MobileNav.atom);
	const setGLoadingState = useSetRecoilState(Recoil.GeneralLoading.Atom);
	//Get configuration
	const configuration: IConfiguration = useRecoilValue(Recoil.Configuration.Selector);
	const location = useLocation();

	const setTitle = (title: string) => {
		const portalName = title ? title : 'Loading...';
		document.title = portalName;
	};

	useEffect(() => {
		setTitle(configuration.name);
		handleNotificationPopup(configuration.settings.app.startupNotification);

		const da = configuration.translations['da-DK'];
		const en = configuration.translations['en-US'];
		const kl = configuration.translations['kl-GL'];
		i18n.addResourceBundle(da.language, 'translations', da.content, false, true);
		i18n.addResourceBundle(en.language, 'translations', en.content, false, true);
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (kl) i18n.addResourceBundle(kl.language, 'translations', kl.content, false, true);
	}, [configuration]);

	/**
	 * Handles notification popup if set in configuration
	 */
	const handleNotificationPopup = (notificationSettings?: {
		title: string;
		description: string;
		duration: number;
		active: boolean;
		begin?: number;
		expire?: number;
	}) => {
		const now = new Date();
		const beginDate = notificationSettings?.begin ? new Date(notificationSettings.begin * 1000) : new Date();
		const expireDate = notificationSettings?.expire ? new Date(notificationSettings.expire * 1000) : new Date();

		if (notificationSettings?.active && beginDate <= now && expireDate >= now) {
			antdNotification.info({
				key: 'startup-notification',
				message: notificationSettings.title,
				description: (
					<div
						className={'notificationHtml'}
						dangerouslySetInnerHTML={{
							// eslint-disable-next-line @typescript-eslint/naming-convention
							__html: notificationSettings.description,
						}}
					/>
				),
				duration: notificationSettings.duration,
				className: 'notificationPopupContainer',
			});
		}
	};

	/**
	 * Handles translations
	 */
	const [t, i18n] = useTranslation();
	const getLanguage = useRecoilValue(Recoil.Language.Atom);
	useEffect(() => {
		i18n.changeLanguage(getLanguage);
	}, [getLanguage]);

	/**
	 * Set and detect about the app is Mobile or Desktop
	 */
	const setScreenTypeS = useSetRecoilState(Recoil.ScreenType.Atom);
	const setScreenLandscapeS = useSetRecoilState(Recoil.ScreenLandscape.Atom);
	const screenIsMobile = useMediaQuery({ maxWidth: 575 });
	const screenIsLandscape = useMediaQuery({ orientation: 'landscape', maxHeight: 550 });

	useEffect(() => {
		setScreenTypeS(screenIsMobile || screenIsLandscape ? EScreenType.MOBILE : EScreenType.DESKTOP);
		setScreenLandscapeS(screenIsLandscape);
	}, [screenIsMobile, screenIsLandscape]);

	/* Submit handler for CRONOS application form. */
	const onFormSubmit = (form: ISubmitFormResponse): Promise<void> => {
		let content = '';
		Object.keys(form.values).forEach((key) => {
			content = content + `<b>${capitalizeFirst(key)}:</b><br/>${key === 'username' ? 'ek_ext_' : ''}${form.values[key]}<br/><br/>`;
		});
		const payloadHtml = `<div>${content}</div>`;
		return new Promise((resolve, reject) => {
			/* Send email */
			form.email &&
				sendMail({ ...form.email, html: payloadHtml })
					.then(() => resolve())
					.catch(() => reject());
		});
	};

	return (
		<>
			{configuration.maintenance.active ? (
				<MaintenanceView
					title={configuration.maintenance.title}
					subtitle={configuration.maintenance.subtitle}
					screenState={useRecoilValue(Recoil.ScreenType.Atom)}
				/>
			) : (
				<MainLayout
					loadOverlayProps={{
						loading: useRecoilValue(Recoil.GeneralLoading.Atom),
						title: 'Naviair Briefing Application',
						subtitle: 'Powered by Naviair',
					}}
					/* Should always be DESKTOP mode, because we dont use mobile Nav as in UTM frontend  */
					screenState={EScreenType.DESKTOP}
					navbar={
						<SideMenu
							mobileNav={showMobileNav}
							content={configuration.settings.app.menu.map((obj) => {
								return {
									...obj,
									title: t(obj.title),
									content: obj.children,
								};
							})}
						/>
					}
					header={<Header isMobile={{ showNav: showMobileNav, setShowNav: setShowMobileNav }} />}>
					<Routes>
						<Route path={'/'} element={<Navigate to={`${location.pathname}/map`} />} />
						<Route path={'map'} element={<Map zones={configuration.settings.map.zones} />} />
						<Route path={'page/:pageKey'} element={<PageViewWrapper onFormSubmit={onFormSubmit} onLoadFinish={() => setGLoadingState(false)} />} />
						<Route path={'aftn/*'} element={<InfoPage />} />
						<Route path={'*'} element={<Navigate to={'/error/404'} />} />
					</Routes>
				</MainLayout>
			)}
		</>
	);
};

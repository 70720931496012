// eslint-disable-next-line eslint-comments/disable-enable-pair
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { IAftnMessage, INotam } from '@naviair-utm/node-shared-interfaces';
import './styles.scss';
import { NotamContent, NotamHeader, NotamHeaderMobile } from '../../AftnMessages/Notam';

export const NotamCols: ColumnsType<IAftnMessage<INotam>> = [
	{
		title: 'ICAO',
		dataIndex: 'icao',
		render: (_, obj) => (
			<div className={'aftnMessageTable'}>
				<NotamHeader message={obj} />
				<NotamContent message={obj} />
			</div>
		),
	},
];

export const NotamColsMobile: ColumnsType<IAftnMessage<INotam>> = [
	{
		title: 'ICAO',
		dataIndex: 'icao',
		render: (_, obj) => (
			<div className={'aftnMessageTable'}>
				<NotamHeaderMobile message={obj} />
				<NotamContent message={obj} />
			</div>
		),
	},
];

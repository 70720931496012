// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/forbid-dom-props */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { IAftnMessage, ISigmet } from '@naviair-utm/node-shared-interfaces';
import './styles.scss';
import { convertTime, tag } from './helpers';

export const SigmetCols: ColumnsType<IAftnMessage<ISigmet>> = [
	{
		title: 'ICAO',
		dataIndex: 'icao',
		render: (_, obj) => (
			<div className={'aftnMessageTable'}>
				<div className={'aftnRow aftnHeader'}>
					<div className={'aftnCol bold'}>{`${obj.icao}`}</div>
					<div className={'aftnCol bold'}>{`${getSigmetId(obj)}`}</div>
					<div className={'aftnCol right'}>{tag(convertTime(obj.validity?.start))}</div>
					<div className={'aftnCol right'}>{tag(convertTime(obj.validity?.end))}</div>
				</div>
				<div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', flex: 4, minWidth: 300 }} className={'aftnRow aftnContent'}>
					<div className={'aftnCol italic'}>{obj.parsedMessage.body}</div>
				</div>
			</div>
		),
	},
];

/* eslint-enable @typescript-eslint/naming-convention */

export const getSigmetId = (sigmet: IAftnMessage<ISigmet>): string => {
	return `${sigmet.parsedMessage.series}${String(sigmet.parsedMessage.seriesIndex).padStart(2, '0')}`;
};

import { Locale } from 'antd/lib/locale-provider';
import { atom, selector } from 'recoil';
// import { CookieHandler, ECookies } from '../../Utils/CookieHandler';
// const { setCookie, getCookies, getCookie, getAcceptedCookies, setAcceptedCookies } = CookieHandler();
import daDK from 'antd/lib/locale/da_DK';
import enUS from 'antd/lib/locale/default';

export enum ELanguages {
	ENGLISH = 'en-US',
	DANISH = 'da-DK',
	GREENLANDIC = 'kl-GL',
}

/**Current language selected on site */
export const selectorLanguage = selector<`${ELanguages}`>({
	key: 'languageSelector',
	get: async (): Promise<ELanguages> => {
		//todo cookie support
		// const cookies = getAcceptedCookies();
		// const cookieLanguageSettings: string = getCookie(ECookies.LANGUAGE_SETTINGS);
		// if (cookies.functionality && cookieLanguageSettings !== undefined) {
		// 	return cookieLanguageSettings;
		// } else {
		// 	return 'da-DK';
		// }
		return ELanguages.DANISH;
	},
	set: ({ set }, newValue) => {
		//todo cookie support
		// const cookies = getAcceptedCookies();
		// if (cookies.functionality) {
		// 	setCookie(ECookies.LANGUAGE_SETTINGS, newValue, 30);
		// }
		set(atomLanguage, newValue);
	},
});
/**Atom for current language choice.
 * @see {@link selectorLanguage}
 */
export const atomLanguage = atom({
	key: 'activeLanguage',
	default: selectorLanguage,
});

/**The selector for the Antd ConfigProvider (Antd Localization) */
export const selectorConfigProvider = selector({
	key: 'configproviderSelector',
	get: ({ get }): Locale => {
		const state = get(atomLanguage);
		switch (state) {
			case 'kl-GL': //todo kl-gl language support
			case 'en-US':
				return enUS;
			default:
				return daDK;
		}
	},
});

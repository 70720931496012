// eslint-disable-next-line eslint-comments/disable-enable-pair
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { IAftnMessage, ISnowtam } from '@naviair-utm/node-shared-interfaces';
import { SnowtamContent, SnowtamHeader } from '../../AftnMessages/Snowtam';

export const SnowtamCols: ColumnsType<IAftnMessage<ISnowtam>> = [
	{
		title: 'ICAO',
		dataIndex: 'icao',
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		render: (_, obj) => {
			return (
				<div className={'aftnMessageTable'}>
					<SnowtamHeader message={obj} />
					<SnowtamContent message={obj} />
				</div>
			);
		},
	},
];

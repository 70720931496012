// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/forbid-dom-props */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { TMetarTaf } from '../../../interfaces';
import './styles.scss';
import { MetarTafContent, MetarTafContentMobile, MetarTafHeader } from '../../AftnMessages/MetarTaf';

export const MetarTafCols: ColumnsType<TMetarTaf> = [
	{
		title: 'ICAO',
		dataIndex: 'icao',
		render: (_, obj) => (
			<div className={'aftnMessageTable'}>
				<MetarTafHeader {...obj} />
				<MetarTafContent {...obj} />
			</div>
		),
	},
];

export const MetarTafColsMobile: ColumnsType<TMetarTaf> = [
	{
		title: 'ICAO',
		dataIndex: 'icao',
		render: (_, obj) => (
			<div className={'aftnMessageTable'}>
				<MetarTafHeader {...obj} />
				<MetarTafContentMobile {...obj} />
			</div>
		),
	},
];
/* eslint-enable @typescript-eslint/naming-convention */

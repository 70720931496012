/**
 * Round a number to a fixed amount of decimals.
 * @param num The number to rund
 * @param decimals Count of decimals. Default 0
 */
export const roundToDecimals = (num: number, decimals?: number): number => {
	// Find the multiplier as a power of 10 or just 1 (will give 0 decimals) if not provided
	const multiplier = decimals === undefined ? 1 : Math.pow(10, decimals);

	// Add EPSILON to the number to fix some rounding errors that might occur with infinite numbers
	return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
};
